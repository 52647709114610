import React from 'react';
import { Card, Col, Button } from 'react-bootstrap';
import { Link } from 'react-router-dom';
import { LazyLoadImage } from 'react-lazy-load-image-component';

const EventCard = ({ g, prefix, flag }) => {
	return (
		<Col md={6} xs={6}>
			<Card style={{ maxWidth: "500px" }} className="eventos_card">
				<Link style={{ textDecoration: "unset" }} to={g.url}>
					<Card.Header as={"h2"}>{!flag ? g.h2 : g.game}</Card.Header>
				</Link>
				<Card.Body>
					<LazyLoadImage src={prefix + g.img + ".webp"} />
				</Card.Body>
				<Card.Footer>
					<Link rel="nofollow" to={g.url}>
						<Button
							as="span"
							style={{
								width: "100%",
								fontWeight: 600,
								borderRadius: "1rem",
							}}
						>
							VER INFORMACIÓN
						</Button>
					</Link>
				</Card.Footer>
			</Card>
		</Col>
	);
};

export default EventCard;
