import gameCSS from "../../CSS/games.module.css";
import { Button } from "react-bootstrap";
import { Slide } from "react-awesome-reveal";
import { Arrowrightw } from "../../icons";
import { replaceSpanishCharacters } from "./Games";

function LandscapeGame({ game }) {
  const names = {
    PT: "PlayTech",
    PRP: "Pragmatic Play",
  };
  const gamePrefix = `https://m.codere.com.co/deportesCol/#/casino/SlotsPage?lobby=Colombia&playgame=`;
  const imgPrefix = "https://www.codere.com.co/Colombia/images/inisio";

  return (
    <div className={gameCSS.landscapeGame}>
      <div className={gameCSS.landscape_card}>
        <a
          href={`${gamePrefix}${replaceSpanishCharacters(game.name)}`}
          rel="nofollow"
        >
          <img
            className="landscapeGameImage"
            src={`${imgPrefix}/${replaceSpanishCharacters(game.name)
              .toLowerCase()
              .replaceAll(/ /g, "-")}.webp`}
            alt={game.name}
          />
          <Slide direction="top" duration={1500}>
            <img
              className={`${gameCSS.logo} landscape-logo-${game.id}`}
              src={`${imgPrefix}/${replaceSpanishCharacters(game.name)
                .toLowerCase()
                .replaceAll(/ /g, "-")}-logo.webp`}
              alt={`${game.name}-logo`}
            />
          </Slide>
        </a>
        <div className={gameCSS.game_info}>
          <h3 className={gameCSS.landscapeGameTitle}>
            {game.name.split(" ").slice(1).join(" ")}
          </h3>
          <div className={gameCSS.landscapeGameSponsor}>
            {names[game.name.split(" ")[0]]}
          </div>
        </div>
        <div className={gameCSS.jugar_div}>
          <Button
            className="jugar-regis rounded-pill d-flex justify-content-around align-items-center"
            size="lg"
            href={`${gamePrefix}${replaceSpanishCharacters(game.name)}`}
          >
            Juega Ahora
            <div id={gameCSS.game_svg} style={{ margin: "2px 0 0 5px" }}>
              <Arrowrightw />
            </div>
          </Button>
        </div>
      </div>
    </div>
  );
}

export default LandscapeGame;
