import { Container, Button } from "react-bootstrap";
import { LazyLoadImage } from "react-lazy-load-image-component";
import { CodereHelmet } from "../../../../App";
import { useEffect } from "react";
import { floating } from "../../helpers";

const Baloncesto = (props) => {
  const prefix =
    "https://www.codere.com.co/Colombia/images/seo-sport/eventos-dep/basketball";

  useEffect(() => {
    let json = {
      "@context": "https://schema.org",
      "@type": "FAQPage",
      mainEntity: [
        {
          "@type": "Question",
          name: "¿Quién ha ganado más mundiales de baloncesto?",
          acceptedAnswer: {
            "@type": "Answer",
            text: "Estados Unidos es el país que lidera el medallero general del Mundial. En la era profesional, el equipo se consagró campeón olímpico en 1992, 1996, 2000, 2008, 2012, 2016 y 2020. ",
          },
        },
        {
          "@type": "Question",
          name: "¿Cuándo es el próximo Mundial de Baloncesto?",
          acceptedAnswer: {
            "@type": "Answer",
            text: "El Mundial de Baloncesto suele llevarse a cabo en años impares entre los Juegos Olímpicos de verano. Generalmente, se celebra durante los meses de agosto y septiembre. Sin embargo, las fechas exactas pueden variar dependiendo de la programación y la sede del evento.",
          },
        },
      ],
    };
    let title = "Apuestas Mundial de Baloncesto » Bono de $200.000 | Codere®";
    let desc =
      "Los mejores partidos del campeonato mundial de baloncesto 🏀 se viven por Codere. Apuesta online con las mejores cuotas del mercado.";

    let can =
      "https://www.codere.com.co/eventos-deportivos/apuestas-mundial-baloncesto";

    CodereHelmet(title, desc, can, [json]);
  }, []);

  return (
    <>
      {floating("Recorrer la pista", false)}

      <div
        className="top-bg-seo"
        style={{
          backgroundImage: `url(${prefix}/${
            props.flag ? "M" : "D"
          }-header.jpg)`,
          backgroundSize: "cover",
        }}
      ></div>
      <Container>
        <h1 className="text-center codere-green fw-normal mt-4">
          Guía de apuestas para el Mundial de Baloncesto FIBA
        </h1>
        <h2 className="codere-green">Historia del Mundial de Basketball</h2>
        <p>
          Fue creado en una reunión del Congreso Mundial de la FIBA en los
          Juegos Olímpicos de 1948 en Londres. El secretario general, Renato
          William Jones, instó a crear un Campeonato del Mundo, similar a la
          Copa Mundial de Fútbol, que se disputara cada cuatro años entre dos
          ediciones de los Juegos Olímpicos.
        </p>
        <p>
          El Congreso, teniendo como ejemplo el exitoso torneo olímpico de 23
          selecciones durante ese año, aceptó la propuesta, creando el{" "}
          <a href="https://www.codere.com.co/eventos-deportivos">
            torneo deportivo
          </a>{" "}
          en 1950 donde Argentina fue seleccionada como anfitrión, en gran parte
          porque era el único país dispuesto a hacerse cargo. Argentina
          aprovechó la ventaja de ser anfitriona, ganando todos los partidos y{" "}
          <b>
            convirtiéndose en el primer vencedor del Campeonato Mundial de
            Baloncesto.
          </b>
        </p>
        <p>
          Los primeros cinco torneos se celebraron en Sudamérica, y los equipos
          del continente americano dominaron el campeonato, ganando ocho de
          nueve medallas en los tres primeros mundiales. A partir de 1963, sin
          embargo, los equipos de Europa Oriental –la Unión Soviética y
          Yugoslavia en particular– comenzaron a plantar cara a los equipos
          americanos.
        </p>
        <p>
          En 1994, los jugadores profesionales participaron por primera vez en
          el torneo. Aunque los Estados Unidos inicialmente dominaron con los
          jugadores de la NBA, otras naciones utilizaron finalmente a jugadores
          profesionales para ponerse a la altura de las cuatro potencias.
        </p>
        <p>
          En 2002, Argentina, liderada por cuatro futuros jugadores de la NBA,
          consiguió la medalla de plata, mientras que el jugador de la NBA y MVP
          del torneo, Dirk Nowitzki, guió a Alemania al bronce. Mientras tanto,
          el equipo estadounidense, formado en totalidad por jugadores de la
          NBA, cayó hasta la sexta plaza.
        </p>
        <p>
          En 2006, España derrotó a Grecia en la primera aparición en una final
          de ambas selecciones, convirtiéndose en el séptimo equipo en ganar el
          Campeonato Mundial. Esta nueva era de igualdad convenció a la
          Organización para expandir el torneo a (24) equipos en las ediciones
          de 2006 y 2010.
        </p>
        <LazyLoadImage
          className="lazyloadimg"
          src={`${prefix}/${props.flag ? "M" : "D"}-Basketball-1.jpg`}
        />
        <h2 className="codere-green mt-4 mb-2">
          ¿Cómo se clasifica al Campeonato Mundial de Baloncesto?
        </h2>
        <p>
          Para clasificar al Campeonato Mundial de Baloncesto, los equipos deben
          participar en torneos de clasificación organizados por FIBA, la
          Federación Internacional de Baloncesto. Estos torneos suelen tener
          lugar durante varios meses, donde los equipos compiten para asegurar
          su lugar en el campeonato.
        </p>
        <p>
          El Campeonato Mundial de Baloncesto generalmente se lleva a cabo cada
          cuatro años, con los meses más comunes para el torneo siendo agosto y
          septiembre.
        </p>
        <h3 className="codere-green mt-4 mb-2">
          Distribución de plazas para próximos mundiales
        </h3>
        <ul>
          <li>País Anfitrión: 1 plaza</li>
          <li>
            FIBA Américas (Norte, Centro, Sudamérica y el Caribe): 7 plazas
          </li>
          <li>FIBA Asia y FIBA Oceanía (Asia y Oceanía): 7 plazas</li>
          <li>FIBA Europa (Europa): 12 plazas</li>
          <li>FIBA África (África): 5 plazas</li>
        </ul>
        <h3 className="codere-green mt-4 mb-2">
          Máximos ganadores, los Goats del baloncesto
        </h3>
        <p>
          Los máximos ganadores de la Copa Mundial de Baloncesto incluyen a
          Estados Unidos, Yugoslavia y la Unión Soviética. En tiempos más
          recientes, España ha emergido como un contendiente formidable,
          consolidando su posición como una fuerza destacada en el baloncesto
          mundial.
        </p>

        <LazyLoadImage
          className="lazyloadimg"
          src={`${prefix}/${props.flag ? "M" : "D"}-Basketball-2.jpg`}
        />
        <h3 className="codere-green mt-4">Jugadores Colombianos en la NBA</h3>
        <p>
          Jaime Echenique es el primer colombiano en la NBA, el equipo de la
          capital de Estados Unidos llamó a Echenique para que reemplazara el
          espacio de Montrezl Harrell, quien entró en protocolo de prevención
          por Covid-19. A pesar de ser sólo por 10 días, el barranquillero
          entrará en la historia como el primer jugador de baloncesto colombiano
          en jugar en la NBA. Álvaro Teherán estuvo cerca de hacerlo con los
          76ers de Filadelfia en 1992 mientras que Braian Angola quedó
          descartado en el draft de 2018.
        </p>

        <h2 id={"anchor-2"} className="codere-green">
          Tipos de Apuestas en Baloncesto
        </h2>
        <p>
          Te presentamos que tipos de{" "}
          <a
            href="https://m.codere.com.co/deportesCol/#/HomePage"
            rel="nofollow"
          >
            apuestas deportivas{" "}
          </a>{" "}
          podrás encontrar y hacer en línea desde Codere:
        </p>

        <ul>
          <li>1,2 Ganador del partido</li>
          <li>Puntaje; Más o Menos</li>
          <li>Ganador primer tiempo</li>
          <li>Puntaje primer tiempo; Mas o Menos</li>
          <li>Local y más o menos puntos</li>
          <li>Visitante y más o menos puntos</li>
        </ul>

        <p>¿Por cuál mercado te irías? </p>

        <h2 className="codere-green">
          Preguntas frecuentes sobre el Mundial de Baloncesto
        </h2>

        <h3 className="codere-green mt-4 mb-2">
          ¿Quién ha ganado más mundiales de baloncesto?
        </h3>
        <p>
          Estados Unidos es el país que lidera el medallero general del Mundial.
          En la era profesional, el equipo se consagró campeón olímpico en 1992,
          1996, 2000, 2008, 2012, 2016 y 2020.
        </p>

        <h3 className="codere-green mt-4 mb-2">
          ¿Cuándo es el próximo Mundial de Baloncesto?
        </h3>
        <p>
          El Mundial de Baloncesto suele llevarse a cabo en años impares entre
          los Juegos Olímpicos de verano. Generalmente, se celebra durante los
          meses de agosto y septiembre. Sin embargo, las fechas exactas pueden
          variar dependiendo de la programación y la sede del evento.
        </p>
        <LazyLoadImage
          className="lazyloadimg"
          src={`${prefix}/${props.flag ? "M" : "D"}-Basketball-3.jpg`}
        />

        <Button
          href="https://m.codere.com.co/deportesCol/#/mso/RegistroCONewPage"
          className="mt-5 seo-reg-btn"
        >
          Regístrate
        </Button>
      </Container>
    </>
  );
};

export default Baloncesto;
