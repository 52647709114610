import { useState, useEffect } from "react";

import sportCSS from "../../CSS/sportgames.module.css";

import { Swiper, SwiperSlide } from "swiper/react";
import { Autoplay, Lazy, Navigation } from "swiper";
import { Image } from "react-bootstrap";
import axios from "axios";
import { BarLoader } from "react-spinners";
import "swiper/css/navigation";

import { LazyLoadImage } from "react-lazy-load-image-component";
import myStore from "../../mobX/Store";
import { observer } from "mobx-react";
import SegmentedCard from "./SegmentedCard";

const primeraA = { id: "3069005120", name: "Primera A" };
const premierLeague = { id: "2817453708", name: "Premier League" };

// const PrimeraDivision = "2903511051";
// const uefa = "2817453712";

const banners = ""; // change to "banners"
const environment = ""; // change to "co" on prd;

const URL = `https://coderesbgonlinesbs${banners}${environment}.azurewebsites.net/api/feeds/leagues/leagueId/1/GetEventsByLeagueAndMarketId`;

const headers = {
  CodereAffiliateApiKey: process.env.REACT_APP_KEY,
  CodereAffiliateApiSecret: process.env.REACT_APP_SECRET,
};

const spinnerCss = { margin: "auto", marginTop: "4rem", marginBottom: "2vw" };

const SportGames = observer(() => {
  const breakpoints = {};
  const [data, setData] = useState([]);
  const [showSpinner, setShowSpinner] = useState(true);
  const [gamesTitle, setGamesTitle] = useState("");
  const [fallback, setFallback] = useState(false);

  // revoke the 1X2 from server side

  useEffect(() => {
    fetchGames(primeraA, 10.5);
  }, []);

  const fetchGames = (league, multiply) => {
    console.warn(`looking for ${league.name} league | id: ${league.id}.`);
    let start = new Date();

    axios
      .get(URL.replace("leagueId", league.id), {
        headers,
        timeout: 1000 * multiply,
      })
      .then((res) => {
        // get only the first 6 games.
        let end = new Date();
        const filteredData = res.data
          .sort((a, b) => new Date(a.StartDate) - new Date(b.StartDate))
          .filter((item) => item.Games.length > 0);
        // .slice(0, 6);

        console.log(res);

        if (!res.data.length) return Promise.reject("fk u");

        setData(filteredData);
        setGamesTitle(league.name);
        console.log(
          `league found: ${league.name} | proccess time: ${
            end.getTime() - start.getTime()
          }ms`
        );

        setShowSpinner(false);
      })
      .catch((err) => {
        let end = new Date();
        console.error(
          `league ${league.name} wasn't found | proccess time: ${
            end.getTime() - start.getTime()
          }ms`
        );
        if (league.id === premierLeague.id) setShowSpinner(false);
        if (!data.length && league.id === primeraA.id)
          fetchGames(premierLeague, 0.5);
        if (!data.length && league.id === premierLeague.id) setFallback(true);
      });
  };

  function dateFix(date) {
    let x = new Date(date);
    let day = x.getDate();
    let month = x.getMonth() + 1;

    if (day < 10) day = "0" + day;
    if (month < 10) month = "0" + month;

    return day + "/" + month + "/" + x.getFullYear();
  }

  function getGameHour(date) {
    let x = new Date(date);
    let hours = x.getHours(),
      mins = x.getMinutes();
    if (x.getHours() < 10) hours = "0" + x.getHours();
    if (x.getMinutes() < 10) mins = "0" + x.getMinutes();
    return hours + ":" + mins;
  }

  return (
    <>
      {showSpinner ? (
        <>
          <BarLoader color="#79c000" cssOverride={spinnerCss} />
          <p style={{ textAlign: "center", color: "#fff" }}>Loading ...</p>
        </>
      ) : (
        <div className={sportCSS.sport_games_slider}>
          <>
            {data.length && (
              <h2 className="codere-green text-uppercase">{gamesTitle}</h2>
            )}

            <Swiper
              id="sportgames"
              breakpoints={{
                320: {
                  slidesPerView: 1.1,
                },
                425: { slidesPerView: 1.35 },
                768: { slidesPerView: 2.3 },
                1024: {
                  slidesPerView: data.length < 2 ? 1 : 4.3,
                },
              }}
              modules={[Autoplay, Lazy, Navigation]}
              lazy={{ loadPrevNext: true, loadPrevNextAmount: 1 }}
              autoplay={{ delay: 9400 }}
              navigation={!myStore.flag}
              spaceBetween={myStore.flag ? 5 : 10}
            >
              {data.map((game, _) => (
                <SwiperSlide key={game.Name}>
                  <SegmentedCard game={game} />
                </SwiperSlide>
              ))}
            </Swiper>
          </>
        </div>
      )}
      {fallback && (
        <Image
          style={{ width: "100%" }}
          width={myStore.flag ? "370" : "800"}
          height={myStore.flag ? "160" : "220"}
          className="mb-5"
          src={`https://www.codere.com.co/assets/fallback-${
            myStore.flag ? "mobile" : "desktop"
          }.webp`}
        />
      )}
    </>
  );
});

export default SportGames;
