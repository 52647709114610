import { Button, Col, Container, Row, Table } from "react-bootstrap";
import { LazyLoadImage } from "react-lazy-load-image-component";
import { CodereHelmet } from "../../../../App";
import { useEffect } from "react";
import TableOfContents from "../../TableOfContents";
import { floating } from "../../helpers";

const CuotasFootball = (props) => {
  const prefix =
    "https://www.codere.com.co/Colombia/images/seo-sport/cuotas-deportivas";

  useEffect(() => {
    let json = {
      "@context": "https://schema.org",
      "@type": "FAQPage",
      mainEntity: [
        {
          "@type": "Question",
          name: "¿Cuándo y dónde será la copa del mundo de 2026?",
          acceptedAnswer: {
            "@type": "Answer",
            text: "FIFA anunció las 16 sedes de la Copa Mundial de 2026. Y por supuesto las ciudades que recibirán al evento más importante del deporte. Tres serán en México, dos en Canadá y las once restantes en Estados Unidos.",
          },
        },
        {
          "@type": "Question",
          name: "¿Cuántos países clasifican al mundial?",
          acceptedAnswer: {
            "@type": "Answer",
            text: "En la reciente edición del Mundial en total clasificaron 32 Selecciones. Sin embargo, para el Mundial 2026, que se realizará en Estados Unidos, Canadá y México, por primera vez, tendrá un total de 48 selecciones. De esta forma, la Copa Mundial 2026 tendrá 16 ciudades anfitrionas de la primera edición del torneo más emblemático del balompié mundial en enfrentar a 48 equipos.",
          },
        },
        {
          "@type": "Question",
          name: "¿Qué país ha organizado más Mundiales en la historia?",
          acceptedAnswer: {
            "@type": "Answer",
            text: "Brasil sigue liderando la lista, no solo es el país que más Mundiales ha ganado, con un total de cinco, sino que también ha sido anfitrión del torneo en dos ocasiones. Ningún país ha albergado más de dos torneos, y los cinco grandes continentes han organizado al menos un Mundial.",
          },
        },
        {
          "@type": "Question",
          name: "¿Cuándo inician los procesos de clasificación al Mundial de 2026?",
          acceptedAnswer: {
            "@type": "Answer",
            text: "Las Eliminatorias para el Mundial de Canadá, Estados Unidos y México 2026 comenzarán en este 2023, el fixture de las próximas eliminatorias todavía no está definido, se sorteará en las primeras semanas del año, y el torneo comenzará en marzo de 2023 con una doble fecha y terminará en octubre de 2025.",
          },
        },
      ],
    };

    let title = "Apuestas de Fútbol en línea » Bono de $200.000 | Codere®";
    let desc =
      "En esta guía encontrarás información completa sobre las apuestas de fútbol ⚽ e información que deberás conocer para realizar tus apuestas deportivas en línea. ";

    let can = "https://www.codere.com.co/cuotas-deportivas/apuestas-futbol";

    CodereHelmet(title, desc, can, [json]);
  }, []);

  const table_of_contents_list = [
    {
      title: "Primeros pasos en el mundo de las apuestas de fútbol",
      id: "anchor-1",
    },
    {
      title: "Tipos de apuestas de futbol en Codere Colombia",
      id: "anchor-2",
    },
    {
      title: "Conoce las Cuotas",
      id: "anchor-3",
    },
    {
      title: "¿Cómo hacer tus propios pronósticos de apuestas de fútbol?",
      id: "anchor-4",
    },
    {
      title: "Preguntas frecuentes",
      id: "anchor-5",
    },
  ];

  const selecciones = {
    headers: ["Apuesta", "Cuota", "Ganancia", "Total a retirar"],
    list: [{ a: "$200.000", b: "2,00", c: "$200.000", d: "$200.000" }],
  };

  const tableGen = (tbl) => {
    return (
      <Table bordered responsive style={{ color: "#fff" }}>
        <thead>
          <tr>
            {tbl.headers.map((h, k) => (
              <th key={k}>{h}</th>
            ))}
          </tr>
        </thead>
        <tbody>
          {tbl.list.map((s, k) => (
            <tr key={k}>
              <td>{s.a}</td>
              <td>{s.b}</td>
              <td>{s.c}</td>
              <td>{s.d}</td>
            </tr>
          ))}
        </tbody>
      </Table>
    );
  };

  return (
    <>
      {floating("Una fiesta futbolera", false)}

      <div
        className="top-bg-seo"
        style={{
          backgroundImage: `url(${prefix}/${
            props.flag ? "M" : "D"
          }-futbolHeader.webp)`,
          backgroundSize: "cover",
        }}
      />
      <Container className="seo">
        <h1 className="text-center codere-green fw-normal mt-4">
          Apuestas Fútbol, la guía que debes conocer
        </h1>
        <p>
          En esta guía encontrarás información completa sobre las apuestas de
          fútbol y todos los datos necesarios que deberás conocer para realizar
          tus <a href="https://www.codere.com.co/">apuestas deportivas</a>.
        </p>
        <p>
          Aquí aprenderás sobre las formas de apuesta disponibles, cómo calcular
          las probabilidades y cómo analizar los equipos y jugadores antes de
          jugar. También te proporcionaremos consejos y estrategias para
          maximizar tus posibilidades de ganar. ¡No te pierdas esta guía
          completa sobre las apuestas en fútbol!
        </p>
        {/*<LazyLoadImage*/}
        {/*	className="lazyloadimg"*/}
        {/*	src={`${prefix}/${props.flag?"M":"D"}-Soccer-1.jpg`}*/}
        {/*/>*/}

        <TableOfContents table={table_of_contents_list} />

        <h2 id={"anchor-1"} className="codere-green">
          Primeros pasos en el mundo de las apuestas en fútbol
        </h2>
        <p>
          Para hacer una apuesta de fútbol segura y confiable, deberás
          familiarizarte con las reglas de este deporte, e investigar y analizar
          cada jugada, establecer un presupuesto específico, para comprender los
          diferentes tipos de apuestas existentes en esta materia. Ingresa a
          Codere, compara cada precio o movimiento disponible dentro del
          mercado, y no menos importante practica la gestión del riesgo para que
          no te dejes llevar por las emociones. No juegues impulsivamente y
          evita tratar de recuperar pérdidas con apuestas más grandes.
        </p>
        <h2 id={"anchor-2"} className="codere-green">
          Tipos de apuestas
        </h2>
        <p>
          En Codere, te ofrecemos una gran variedad de ofertas para que elijas
          la que más te convenga. Puedes apostar al 1x2, a la doble oportunidad,
          al número de goles, al resultado exacto y mucho más. No te pierdas la
          emoción de los mejores eventos de futbol y aprovecha nuestras{" "}
          <a href="https://www.codere.com.co/cuotas-deportivas">cuotas</a> y
          promociones.
        </p>
        <h3 className="codere-green">Apuesta 1X2</h3>
        <p>
          Es una modalidad que evalúa el resultado de un partido desde una
          perspectiva sencilla. En este tipo de juego, se consideran tres
          posibles resultados: la victoria del equipo local, el triunfo del
          equipo visitante o un empate entre ambos equipos. Por lo tanto, al
          seleccionar esta opción, estás pronosticando quién será el ganador del
          partido o si el juego culminará en empate.
        </p>
        <h3 className="codere-green">Apuesta Doble Oportunidad</h3>
        <p>
          Esta modalidad te brinda una estrategia ingeniosa para aumentar tus
          posibilidades de éxito, permitiéndote elegir dos de las tres opciones:
          victoria local, empate, victoria visitante acompañada de empate, o
          incluso apuesta a victoria local y visitante, proporcionando dos
          oportunidades de acertar y maximiza las posibilidades de obtener
          ganancias.
        </p>
        <h3 className="codere-green">Apuesta Resultado Final</h3>

        <p>
          Una forma de aumentar tus posibilidades de ganar en las apuestas
          deportivas es usar la opción de "resultado final", que te permite
          elegir varios marcadores posibles para un mismo partido y combinarlos
          en un solo ticket. Así, si aciertas alguno de los marcadores, ganas la
          apuesta. Ejemplo, si crees que el equipo A va a ganar al equipo B,
          pero no estás seguro de los goles anotados, puedes apostar por los
          resultados 1-0, 2-0 y 3-0 a tu favorito. Si el partido termina con
          alguno de esos marcadores, tu ticket será ganador.
        </p>
        <h3 className="codere-green">Apuesta Marca Gol Durante el Partido</h3>
        <p>
          Si te gusta jugar por los goleadores, con esta opción puedes elegir si
          un jugador o un equipo anotará al menos un gol. No importa si el
          partido se va a tiempos extra o penaltis, solo cuenta el tiempo
          reglamentario. Por lo que analizar bien las estadísticas y las cuotas,
          será crucial para tu juego.
        </p>
        <h3 className="codere-green">Apuesta Marcan Ambos Equipos</h3>
        <p>
          La opción "ambos marcan" consiste en predecir que los dos equipos que
          se enfrentan lograrán marcar, al menos, un gol durante el partido. No
          importa si el resultado final es un empate o una victoria por la
          mínima, lo único que cuenta es que haya goles en las dos porterías.
        </p>
        <h3 className="codere-green">Apuesta Primer Equipo en Marcar</h3>
        <p>
          Esta apuesta permite predecir qué equipo será el primero en romper el
          cero en el marcador. Así, puedes aprovechar tu conocimiento sobre los
          equipos, sus estilos de juego y sus delanteros más efectivos que puede
          darte buenos beneficios si aciertas.
        </p>
        <h3 className="codere-green">Crea Tu Apuesta</h3>
        <p>
          Esta es la nueva forma de realizar tus pronósticos deportivos; aquí
          puedes realizar múltiples combinaciones en tus partidos de fútbol
          favoritos.
        </p>

        <h3 className="codere-green">
          Así de fácil puedes jugar con Crea tu apuesta:
        </h3>

        <ol>
          <li>Elige el evento de fútbol donde quieres hacer tu apuesta.</li>
          <li>Dale clic en la opción “Crea tu Apuesta”.</li>
          <li>Escoge los mercados que quieras.</li>
          <li>
            Cuando tengas todo listo, añade tus selecciones a tu boleto de
            apuestas.
          </li>
          <li>
            Indica la cantidad de dinero que desees apostar, confirma tu
            pronóstico ¡y listo!
          </li>
        </ol>

        <h2 id={"anchor-3"} className="codere-green">
          Conoce las Cuotas
        </h2>
        <p>
          Las cuotas se expresan en números decimales, como 2,00, 3,50, entre
          otros, representando la cantidad total que se ganaría por cada unidad
          apostada. Por ejemplo:
        </p>

        <div style={{ width: "100%" }}>
          <Row>
            <Col md={12}>{tableGen(selecciones)}</Col>
          </Row>
        </div>

        <h2 id={"anchor-5"} className="codere-green mt-4">
          Preguntas Frecuentes
        </h2>
        <h3 className="codere-green mt-4">
          ¿Cuáles son las mejores ligas para apostar?
        </h3>
        <p>
          Entre las mejores ligas para apostar en Colombia se encuentran la
          Primera División Nacional, la UEFA Champions League, la Premier League
          inglesa, la Liga española, la liga italiana, entre otras.
        </p>
        <h2 id={"anchor-4"} className="codere-green mt-4">
          ¿Cómo hacer tus propios pronósticos de apuestas de fútbol?
        </h2>
        <p>
          Para hacer tus pronósticos en apuestas de fútbol, investiga sobre los
          equipos, analiza estadísticas, considera las condiciones del partido,
          observa las tendencias de los equipos, analiza sus tácticas y
          estrategias de juego.
        </p>
        <h3 className="codere-green mt-4">
          ¿Cómo puedo ver si mi apuesta es ganadora?
        </h3>
        <p>
          Puedes verificar si tu apuesta fue ganadora accediendo a tu cuenta en
          nuestra web y seleccionando la opción "Mis apuestas" para ver el
          resultado de tu juego.
        </p>

        <LazyLoadImage
          className="lazyloadimg"
          src={`${prefix}/${props.flag ? "M" : "D"}-futbolSoccer-3.webp`}
        />
        <Button
          href="https://m.codere.com.co/deportesCol/#/mso/RegistroCONewPage"
          style={{ margin: "auto", fontSize: "1.35rem", padding: "10px 35px" }}
          className="seo-reg-btn mt-5"
        >
          Regístrate
        </Button>
      </Container>
    </>
  );
};

export default CuotasFootball;
