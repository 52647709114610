import { Route, Routes, useLocation } from "react-router-dom";
import Navigations from "../Components/seo/Navigations";
import { useEffect, useState } from "react";
import EventosDeportivos from "../Components/seo/sports/EventosDeportivos";
import CuotasDeportivos from "../Components/seo/sports/CuotasDeportivas";
import Mundial from "../Components/seo/sports/events/Mundial";
import Baloncesto from "../Components/seo/sports/events/Baloncesto";
import MLB from "../Components/seo/sports/events/MLB";
import Superbowl from "../Components/seo/sports/events/Superbowl";
import Casino from "../Components/seo/casino/Casino";
import Blackjack from "../Components/seo/casino/Blackjack";
import Slots from "../Components/seo/casino/Slots";
import Baccarat from "../Components/seo/casino/Baccarat";
import Bingo from "../Components/seo/casino/Bingo";
import Inicio from "../Components/Inicio";
import Jackpots from "../Components/seo/casino/Jackpots";
import Ruleta from "../Components/seo/casino/Ruleta";
import CasinoEnVivo from "../Components/seo/casino/CasinoEnVivo";
import InicioSegmented from "../Components/InicioSegmented";
import { observer } from "mobx-react";
import myStore from "../mobX/Store";
import ApuestasBaloncesto from "../Components/seo/sports/events/ApuestasBaloncesto";
import CuotasFormula from "../Components/seo/sports/events/ApuestasFormula";
import CuotasFootball from "../Components/seo/sports/events/ApuestasFutbol";
import ApuestasCiclismo from "../Components/seo/sports/events/ApuestasCiclismo";
import CuotasNFL from "../Components/seo/sports/events/ApuestasNFL";
import useRoutes from "./useRoutes";
import Tenis from "../Components/seo/sports/events/Tenis";
import Ufc from "../Components/seo/sports/events/UFC";

const MyRouting = observer(() => {
  const location = useLocation();
  const [activated, setActivated] = useState(1);
  // const [contentData, setContentData] = useState(null);
  useRoutes();

  useEffect(() => {
    window.scrollTo(0, 0);
    setActivated(activeHandler);
  }, [location.pathname]);

  const activeHandler = () => {
    switch (location.pathname) {
      case "/casino":
      case "/eventos-deportivos/apuestas-mundial-fifa":
      case "/cuotas-deportivas/apuestas-baloncesto":
        return 1;
      case "/casino/casino-en-vivo":
      case "/eventos-deportivos/apuestas-mundial-baloncesto":
      case "/cuotas-deportivas/apuestas-formula1":
        return 2;
      case "/casino/slots":
      case "/eventos-deportivos/apuestas-juego-de-las-estrellas":
      case "/cuotas-deportivas/apuestas-futbol":
        return 3;
      case "/casino/blackjack":
      case "/eventos-deportivos/apuestas-superbowl":
      case "/cuotas-deportivas/apuestas-ciclismo":
        return 4;
      case "/casino/ruleta":
      case "/cuotas-deportivas/apuestas-nfl":
        return 5;
      case "/casino/jackpots":
      case "/cuotas-deportivas/apuestas-ufc":
        return 6;
      case "/casino/baccarat":
      case "/cuotas-deportivas/apuestas-tenis":
        return 7;
      case "/casino/video-bingo":
        return 8;
      default:
        return 0;
    }
  };
  const typeHandler = () => {
    if (location.pathname.includes("eventos")) return 0;
    else if (location.pathname.includes("casino")) return 1;
    else if (location.pathname.includes("cuotas")) return 2;
    else return false;
  };
  return (
    <>
      {(location.pathname.includes("cuotas") ||
        location.pathname.includes("eventos") ||
        location.pathname.includes("casino")) && (
        <Navigations index={activated} type={typeHandler()} />
      )}
      <Routes>
        {!myStore.segmented ? (
          <Route exact path="/" element={<Inicio flag={myStore.flag} />} />
        ) : (
          <Route
            exact
            path="/"
            element={<InicioSegmented flag={myStore.flag} />}
          />
        )}
        <Route exact path="eventos-deportivos">
          <Route
            exact
            path=""
            element={<EventosDeportivos flag={myStore.flag} />}
          />
          <Route
            exact
            path="apuestas-mundial-fifa"
            element={<Mundial flag={myStore.flag} />}
          />
          <Route
            exact
            path="apuestas-mundial-baloncesto"
            element={<Baloncesto flag={myStore.flag} />}
          />
          <Route
            exact
            path="apuestas-tenis"
            element={<Tenis flag={myStore.flag} />}
          />
          <Route
            exact
            path="apuestas-ufc"
            element={<Ufc flag={myStore.flag} />}
          />
          <Route
            exact
            path="apuestas-juego-de-las-estrellas"
            element={<MLB flag={myStore.flag} />}
          />
          <Route
            exact
            path="apuestas-superbowl"
            element={<Superbowl flag={myStore.flag} />}
          />
        </Route>

        <Route exact path="cuotas-deportivas">
          <Route
            exact
            path=""
            element={<CuotasDeportivos flag={myStore.flag} />}
          />
          <Route
            exact
            path="apuestas-futbol"
            element={<CuotasFootball flag={myStore.flag} />}
          />
          <Route
            exact
            path="apuestas-baloncesto"
            element={<ApuestasBaloncesto flag={myStore.flag} />}
          />
          <Route
            exact
            path="apuestas-ciclismo"
            element={<ApuestasCiclismo flag={myStore.flag} />}
          />
          <Route
            exact
            path="apuestas-nfl"
            element={<CuotasNFL flag={myStore.flag} />}
          />
          <Route
            exact
            path="apuestas-superbowl"
            element={<Superbowl flag={myStore.flag} />}
          />
          <Route
            exact
            path="apuestas-formula1"
            element={<CuotasFormula flag={myStore.flag} />}
          />
          <Route
            exact
            path="apuestas-ufc"
            element={<Ufc flag={myStore.flag} />}
          />
          <Route
            exact
            path="apuestas-tenis"
            element={<Tenis flag={myStore.flag} />}
          />
        </Route>
        {/*SEO Casino */}
        <Route exact path="casino">
          <Route exact path="" element={<Casino flag={myStore.flag} />} />
          <Route
            exact
            path="casino-en-vivo"
            element={<CasinoEnVivo flag={myStore.flag} />}
          />
          <Route
            exact
            path="blackjack"
            element={<Blackjack flag={myStore.flag} />}
          />
          <Route exact path="ruleta" element={<Ruleta flag={myStore.flag} />} />
          <Route exact path="slots" element={<Slots flag={myStore.flag} />} />
          <Route
            exact
            path="jackpots"
            element={<Jackpots flag={myStore.flag} />}
          />
          <Route
            exact
            path="baccarat"
            element={<Baccarat flag={myStore.flag} />}
          />
          <Route
            exact
            path="video-bingo"
            element={<Bingo flag={myStore.flag} />}
          />
        </Route>

        {/* Ayuda  */}
      </Routes>
    </>
  );
});

export default MyRouting;
