import { Swiper, SwiperSlide } from "swiper/react";
import { Autoplay, Lazy } from "swiper";

import "swiper/css";
import "swiper/css/lazy";
import "swiper/css/autoplay";

function GameSwiper({ games }) {
  const imgPrefix = "https://www.codere.com.co/lobby_tiles";
  const gamePrefix = `https://m.codere.com.co/deportesCol/#/casino/SlotsPage?lobby=Colombia&playgame=`;

  return (
    <div className="game-carousel">
      <Swiper
        modules={[Autoplay, Lazy]}
        lazy={{ loadPrevNext: true, loadPrevNextAmount: 1 }}
        autoplay={{ delay: 2000, disableOnInteraction: false }}
        spaceBetween={10}
        slidesPerView={3}
        loop={true}
      >
        {games.map((game, index) => {
            return (
            <SwiperSlide key={index}>
              <a href={`${gamePrefix}${game.name}`} rel={"nofollow"}>
                <img
                  width="50"
                  height="50"
                  src={`${imgPrefix}/${game.name
                    .toLowerCase()
                    .replaceAll(/ /g, "")}_square.jpg`}
                  alt={game.name}
                />
              </a>
            </SwiperSlide>
          );
        })}
      </Swiper>
    </div>
  );
}
export default GameSwiper;
