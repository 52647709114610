import { Accordion, Button } from "react-bootstrap";

export function dynamicAccordion(data) {
  return (
    <Accordion>
      {data.map((pf, i) => (
        <Accordion.Item eventKey={i} key={i}>
          <Accordion.Header as={"h3"}>{pf.title}</Accordion.Header>
          <Accordion.Body>
            {pf.p.map((pi, k) => (
              <p key={k}>{pi}</p>
            ))}
          </Accordion.Body>
        </Accordion.Item>
      ))}
    </Accordion>
  );
}
export function dynamicTable(table) {
  const { headers, rows } = table;

  return (
    <div>
      <table
        className="mb-3 table table-dark table-striped table-bordered table-hover"
        style={{ backgroundColor: "transparent" }}
      >
        {headers && headers.length > 0 && (
          <thead>
            <tr>
              {headers.map((header, index) => (
                <th key={index}>{header}</th>
              ))}
            </tr>
          </thead>
        )}
        <tbody>
          {rows.map((row, rowIndex) => (
            <tr key={rowIndex}>
              {Object.entries(row).map(([key, value], columnIndex) => {
                // do not display IDs
                if (key === "id") {
                  return null;
                }
                return <td key={columnIndex}>{value}</td>;
              })}
            </tr>
          ))}
        </tbody>
      </table>
    </div>
  );
}

export function floating(text, juega) {
  return (
    <div className="floating">
      <span>{text}</span>
      <br />
      <Button
        href={`https://m.codere.com.co/deportesCol/#/${juega ? "casino/CasinoPage?lobby=PlaytechCO" : "mso/RegistroCONewPage"}`}
        rel="nofollow"
        className="floating-btn"
      >
        {juega ? "Juega ahora" : "Regístrate"}
      </Button>
    </div>
  );
}
