import { Accordion, Button, Container } from "react-bootstrap";
import { LazyLoadImage } from "react-lazy-load-image-component";
import { CodereHelmet } from "../../../../App";
import { useEffect } from "react";
import TableOfContents from "../../TableOfContents";
import { floating } from "../../helpers";

const Tenis = (props) => {
  const prefix =
    "https://www.codere.com.co/Colombia/images/seo-sport/cuotas-deportivas";

  useEffect(() => {
    let json = {
      "@context": "https://schema.org",
      "@type": "FAQPage",
      mainEntity: [
        {
          "@type": "Question",
          name: "¿Qué significa +1.5 en tenis?",
          acceptedAnswer: {
            "@type": "Answer",
            text:
              "Apostar en +1.5 en tenis significa que tienes una ventaja `en teoría` de 1.5 sets antes de que comience el juego,es decir que si el" +
              " partido queda 2 sets a 0 o 2 sets a 1 tu apuesta será la vencedora.",
          },
        },
        {
          "@type": "Question",
          name: "¿Cómo son las apuestas en el tenis?",
          acceptedAnswer: {
            "@type": "Answer",
            text: "Tienes diferentes formas de realizar tus pronósticos deportivos en el tenis tales como: ganador del partido, hándicap, total de sets o juegos, apuesta por set y la apuesta hacia un jugador en específico durante cualquier partido.",
          },
        },
      ],
    };
    let title = "Aprende Apostar al Tenis »  » Bono de $200.000 | Codere®";
    let desc =
      "Vive las apuestas del 🎾 deporte blanco con Codere disfruta de los grandes torneos y aprovecha las mejores cuotas Tu destino para apuestas está aquí.";

    let can = "https://www.codere.com.co/cuotas-deportivas/apuestas-tenis";

    CodereHelmet(title, desc, can, [json]);
  }, []);
  const table_of_contents_list = [
    {
      title: "Sistema de puntuación en el tenis",
      id: "anchor-1",
    },
    {
      title: "Mejores Jugadores",
      id: "anchor-2",
    },
    {
      title: "Tipos de apuestas",
      id: "anchor-3",
    },
    {
      title: "Consejos para apostar",
      id: "anchor-4",
    },
    {
      title: "Preguntas frecuentes",
      id: "anchor-5",
    },
  ];
  return (
    <>
      {floating("¡Gana en cada set!", false)}
      <div
        className="top-bg-seo"
        style={{
          backgroundImage: `url(${prefix}/${
            props.flag ? "M" : "D"
          }-tenis-Header.jpg)`,
          backgroundSize: "cover",
        }}
      ></div>
      <Container>
        <h1 className="text-center codere-green fw-normal mt-4">
          Apuestas en Tenis, el deporte blanco y sus cuotas
        </h1>

        <p>
          Con Codere tienes múltiples formas de ganar dinero con el “deporte
          blanco” en esta guía tendrás la información para que realices tus
          apuestas efectivas con el tenis.
        </p>
        <TableOfContents table={table_of_contents_list} />
        <h2 id={"anchor-1"} className="codere-green mt-4 mb-2">
          Sistema de puntuación en el tenis
        </h2>
        <p>
          En el tenis hay varios sistemas de puntuación: Juegos, sets, partidos.
        </p>
        <ul>
          <li>
            <u>Juegos:</u> se gana la partida cuando un jugador 40 puntos, si la
            puntuación llega al 40-40 se le conoce como “Deuce” hasta que un
            tenista consigue 2 puntos consecutivos.
          </li>
          <li>
            <u>Sets:</u> un set se gana cuando el jugador llega a ganar seis
            juegos; si un tenista llega a 6 sets, pero el rival llega a 5, el
            set continúa hasta que exista una ventaja de 2 juegos. Algunos
            torneos, cuando el marcador llega a 6-6, se juega al “tie-break”
            juego corto de 7 puntos, con una ventaja de dos puntos.
          </li>
          <li>
            <u>Partidos:</u> Un partido de tenis se gana la mayoría de 3 o 5
            sets, dependiendo del formato del torneo. Para el caso de los
            partidos masculinos se ganan con 5 sets, mientras que los femeninos
            con 3 sets.
          </li>
        </ul>
        {/*<LazyLoadImage*/}
        {/*	className="lazyloadimg"*/}
        {/*	src={`${prefix}/${props.flag?"M":"D"}-Tennis-1.jpg`}*/}
        {/*/>*/}

        <h2 id={"anchor-2"} className="codere-green mt-4 mb-2">
          Mejores Jugadores de tenis en la historia
        </h2>
        <p>
          El tenis ha tenido grandes jugadores masculinos y excelentes tenistas
          femeninas en la historia, dentro de los más destacados se encuentran:
          <strong>
            Jimmy Connors, Roger Federer, Novak Djokovic, Rafael Nadal, Martina
            Navrátilová, Chris Evert, Martina Hingis y Serena Williams.
          </strong>
        </p>

        <h2 id={"anchor-3"} className="codere-green mt-4 mb-2">
          Tipos de apuestas
        </h2>
        <p>
          Con Codere tienes varias alternativas para realizar tus pronósticos
          deportivos en Tenis: ganador del partido, ganador del set 1, ganador
          de set 2, hándicap por juegos, total de juegos más/menos y tiebreak en
          el partido.
        </p>

        <h3 className="codere-green mt-4 mb-2">Ganador del partido </h3>
        <p>
          Esta es la apuesta más sencilla y se trata de elegir qué tenista que
          resulta ganador el encuentro, sin importar el momento del juego.
        </p>
        <h3 className="codere-green mt-4 mb-2">Ganador del Set 1 </h3>
        <p>
          Es la oportunidad perfecta para analizar el arranque del juego y
          anticipar qué jugador tomará la delantera desde el inicio.
        </p>
        <h3 className="codere-green mt-4 mb-2">Ganador del Set 2 </h3>
        <p>
          Este es un momento ideal para evaluar cómo los jugadores se adaptan y
          mantienen su rendimiento a lo largo del encuentro, sobre todo quién
          demostrará mayor consistencia y dominio en esa etapa del juego.
        </p>
        <h3 className="codere-green mt-4 mb-2">Hándicap por juegos </h3>
        <p>
          En el Hándicap por juegos, tú podrás jugar por una ventaja o
          desventaja durante los juegos en un set, en función de la dinámica de
          juego y las fortalezas de los jugadores. Al elegir el Hándicap por
          juegos, puedes aprovechar tu conocimiento del deporte y las
          estadísticas para tomar decisiones más informadas y aumentar tus
          posibilidades de obtener ganancias.
        </p>
        <h3 className="codere-green mt-4 mb-2">Total de juegos Más/Menos </h3>
        <p>
          En esta apuesta podrás predecir cuantos juegos habrá durante el
          partido con la media desde 22.5 hasta 24.5. Esta modalidad de apuesta
          te permite ajustar tus predicciones según tu análisis del desempeño de
          los equipos o jugadores involucrados, ofreciendo una amplia variedad
          de posibilidades para tus pronósticos.
        </p>
        <h3 className="codere-green mt-4 mb-2">Tiebreak en el Partido </h3>
        <p>
          Aquí podrás predecir si todo el partido tendrá o no un “Tiebreak”,
          dependiendo del trámite del compromiso. El marcador de un tiebreak se
          cuenta de forma diferente al de los juegos normales, usando números
          enteros en lugar de puntos convencionales (por ejemplo, 7-5 en lugar
          de 7-6, 10-8 en lugar de 7-5). Si el set se decide en un tiebreak, se
          registra como 7-6 (o el marcador correspondiente) en favor del ganador
          del tiebreak.
        </p>

        <h2 className="codere-green mt-4 mb-2">Crea Tu Apuesta</h2>
        <p>
          Esta es la nueva forma de realizar tus pronósticos deportivos; aquí
          puedes realizar múltiples combinaciones en tus partidos de tenis
          favoritos. Así de fácil puedes jugar con Crea tu apuesta:
        </p>
        <ul>
          <li>Elige el evento tenístico donde quieres hacer tu apuesta.</li>
          <li>Dale clic en la opción “Crea tu Apuesta”.</li>
          <li>Escoge los mercados que quieras.</li>
          <li>
            Cuando tengas todo listo, añade tus selecciones a tu boleto de
            apuestas.
          </li>
          <li>
            Indica la cantidad de dinero que desees apostar, confirma tu
            pronóstico, ¡y listo!
          </li>
        </ul>

        <h2 id={"anchor-4"} className="codere-green mt-4 mb-2">
          Consejos para apostar
        </h2>
        <h3 className="codere-green mt-4 mb-2">
          ¿Qué puedes hacer para apostar en Tenis con Codere?
        </h3>
        <p>
          Puedes investigar a los jugadores, analizar las estadísticas de estos,
          considerar la superficie y las condiciones donde se esté desarrollando
          el partido o torneo y establecer un presupuesto (recuerda que para
          este último ítem es crucial conocer que el juego responsable no es
          ningún juego y que debes apostar con moderación.
        </p>

        <h2 id={"anchor-5"} className="codere-green mt-4 mb-2">
          Preguntas Frecuentes
        </h2>
        <Accordion className="content-accordion  mb-4" alwaysOpen>
          <Accordion.Item className="accordionT454" eventKey="0">
            <Accordion.Header className="codere-green accordionT454">
              ¿Qué significa +1.5 en tenis?
            </Accordion.Header>
            <Accordion.Body className="accordionBody testooo">
              <p>
                Apostar en +1.5 en tenis significa que tienes una ventaja “en
                teoría” de 1.5 sets antes de que comience el juego, es decir que
                si el partido queda 2 sets a 0 o 2 sets a 1 tu apuesta será la
                vencedora.
              </p>
            </Accordion.Body>
          </Accordion.Item>
          <Accordion.Item className="accordionT454" eventKey="1">
            <Accordion.Header className="codere-green accordionT454">
              ¿Cómo son las apuestas en el tenis?
            </Accordion.Header>
            <Accordion.Body className="accordionBody testooo">
              <p>
                Tienes diferentes formas de realizar tus pronósticos deportivos
                en el tenis tales como: ganador del partido, hándicap, total de
                sets o juegos, apuesta por set y la apuesta hacia un jugador en
                específico durante cualquier partido.
              </p>
            </Accordion.Body>
          </Accordion.Item>
        </Accordion>
        <Button
          href="https://m.codere.com.co/deportesCol/#/mso/RegistroCONewPage"
          className="mt-5 seo-reg-btn mb-5"
        >
          Regístrate
        </Button>
        <LazyLoadImage
          className="lazyloadimg"
          src={`${prefix}/${props.flag ? "M" : "D"}-Tennis-2.jpg`}
        />
      </Container>
    </>
  );
};

export default Tenis;
