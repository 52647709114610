import gameCSS from "../../CSS/games.module.css";
import LandscapeGame from "./LandscapeGame";
import PortraitGame from "./PortraitGame";
import GameSwiper from "./GameSwiper";
import gameCarousel from "../../JSON/mob-games.json";
import deskGames from "../../JSON/desktop-games.json";

// Top Games \\
export function replaceSpanishCharacters(text) {
  return text.normalize("NFD").replace(/[\u0300-\u036f]/g, "");
}

function Games({ device }) {
  const titles = ["Mesas en Español", "Juegos Destacados", "Nuevos Juegos"];

  const mobileTitleStyles = {
    color: "#79c000",
    fontWeight: 700,
    textAlign: "center",
    fontSize: "5vw",
    margin: "1.75vw 0",
  };

  return (
    <>
      {device ? (
        <div id="mobile-games-section">
          {gameCarousel.map((games, k) => (
            <div key={k}>
              <div style={mobileTitleStyles}>
                <span>{titles[k]}</span>
              </div>
              <GameSwiper games={games} order={k} />
            </div>
          ))}
        </div>
      ) : (
        <div id={gameCSS.desktop_games}>
          <div>
            <div
              className="text-white fw-bold"
              style={{ fontSize: `1.75${device ? "vw" : "rem"}` }}
            >
              Mesas en Español
            </div>
            <div className={gameCSS.portrait_container}>
              {deskGames.spanish_games.map((game, _) => {
                return (
                  <div className={gameCSS.espanol} key={game.name}>
                    <PortraitGame game={game} />
                  </div>
                );
              })}
            </div>
          </div>

          <div>
            <div
              className="text-white fw-bold mb-4 mt-3"
              style={{ fontSize: `1.75${device ? "vw" : "rem"}` }}
            >
              Mejores Juegos
            </div>
            <div className={gameCSS.landscape_container}>
              {deskGames.top_games.map((game, key) => (
                <div className={gameCSS.mejores} key={key}>
                  <LandscapeGame game={game} />
                </div>
              ))}
            </div>
          </div>

          <div className="mt-5 mb-2">
            <div
              className="text-white fw-bold"
              style={{ fontSize: `1.75${device ? "vw" : "rem"}` }}
            >
              Nuevos Juegos
            </div>
            <div className={gameCSS.portrait_container}>
              {deskGames.bottom_games.map((game, _) => (
                <div className={gameCSS.nuevos} key={game.name}>
                  <PortraitGame game={game} />
                </div>
              ))}
            </div>
          </div>
        </div>
      )}
    </>
  );
}

export default Games;
