import { Button, Container } from "react-bootstrap";
import { LazyLoadImage } from "react-lazy-load-image-component";
import { CodereHelmet } from "../../../../App";
import { useEffect } from "react";
import TableOfContents from "../../TableOfContents";
import { floating } from "../../helpers";
import { SPEEDSIZE_PREFIX } from "../../../../App";
const CuotasNFL = (props) => {
  const prefix =
    `${SPEEDSIZE_PREFIX}https://www.codere.com.co/Colombia/images/seo-sport/cuotas-deportivas`;

  useEffect(() => {
    let json = {
      "@context": "https://schema.org",
      "@type": "FAQPage",
      mainEntity: [
        {
          "@type": "Question",
          name: "¿Quién ha ganado más mundiales de baloncesto?",
          acceptedAnswer: {
            "@type": "Answer",
            text: "Estados Unidos es el país que lidera el medallero general del Mundial. En la era profesional, el equipo se consagró campeón olímpico en 1992, 1996, 2000, 2008, 2012, 2016 y 2020. ",
          },
        },
        {
          "@type": "Question",
          name: "¿Cuándo es el próximo Mundial de Baloncesto?",
          acceptedAnswer: {
            "@type": "Answer",
            text: "La Copa Mundial de Baloncesto se llevará a cabo el viernes 25 de agosto de 2023 hasta el domingo 10 de septiembre y se celebrará conjuntamente en Filipinas, Japón e Indonesia.",
          },
        },
      ],
    };
    let title = "Guía Apuestas en la NFL » Bono de $200.000 | Codere®";
    let desc =
      "Aquí podrás encontrar las diferentes opciones de apuesta para el fútbol americano 🏈, y podrás resolverás tus dudas sobre este juego antes de inciar la aventura.";

    let can = "https://www.codere.com.co/cuotas-deportivas/apuestas-nfl";

    CodereHelmet(title, desc, can, [json]);
  }, []);
  const table_of_contents_list = [
    {
      title: "Breve historia de la NFL",
      id: "anchor-1",
    },
    {
      title: "Sistema de conferencias",
      id: "anchor-2",
    },
    {
      title: "Super Bowl: El gran evento",
      id: "anchor-3",
    },
    {
      title: "Tipos de apuestas",
      id: "anchor-4",
    },
    {
      title: "Preguntas frecuentes",
      id: "anchor-5",
    },
  ];
  return (
    <>
      {floating("Anotaciones y emociones", false)}
      <div
        className="top-bg-seo"
        style={{
          backgroundImage: `url(${prefix}/${
            props.flag ? "M" : "D"
          }-superbowl-Header.webp)`,
          backgroundSize: "cover",
        }}
      ></div>
      <Container>
        <h1 className="text-center codere-green fw-normal mt-4">
          Apuestas NFL: escoge tu posición y disfruta de los mejores eventos
        </h1>
        <p>
          Aquí podrás encontrar las diferentes opciones de{" "}
          <a href="https://www.codere.com.co/">apuesta</a> para el fútbol
          americano, así como también, resolverás tus dudas sobre este juego muy
          popular en los Estados Unidos. .
        </p>

        <TableOfContents table={table_of_contents_list} />

        <h2 id={"anchor-1"} className="codere-green">
          Breve historia de la NFL
        </h2>
        <p>
          La NFL es la liga profesional de fútbol americano de los Estados
          Unidos. Su historia se remonta a 1920, cuando se fundó la Asociación
          Americana de Fútbol Profesional, que cambió su nombre a NFL en 1922.
          Al finalizar la temporada regular, siete equipos de cada conferencia
          clasifican a los playoffs, que son una serie de eliminatorias para
          determinar los campeones de cada conferencia para posteriormente
          enfrentarse en el partido más importante del año: el Super Bowl.
        </p>

        <h3 className="codere-green">
          Principales posiciones en el futbol americano{" "}
        </h3>

        <p>
          Dentro de las posiciones más comunes en este deporte encontramos al
          Quarterback (QB): conocido por ser el líder del equipo en el campo o
          mariscal, este responsable de lanzar el balón y dirigir la ofensiva.
          El Running Back (RB) o corredor principal del equipo, encargado de
          correr con el balón y recibir pases cortos, y el Wide Receiver (WR)
          jugador que corre rutas y recibe pases largos del mariscal de campo.
        </p>
        <h3 className="codere-green"> Puntuaciones en un partido de NFL </h3>

        <p>
          En la NFL, los equipos pueden obtener puntos de varias maneras durante
          cualquier compromiso:
        </p>
        <ul>
          <li>
            <strong>Touchdown (TD):</strong> es cuando avanza el balón hacia la
            zona de anotación del equipo contrario y lo cruza con posesión,
            otorgando 6 puntos al equipo anotador.
          </li>
          <li>
            <strong>Extra-Point (PAT):</strong> Después de anotar un touchdown,
            el equipo tiene la opción de intentar un punto extra. Sus
            integrantes pueden patear el balón entre los postes de gol o
            intentar avanzar el balón hacia la zona de anotación.
          </li>
          <li>
            <strong>Field Goal (FG):</strong> Si un equipo no puede anotar un
            touchdown, puede intentar patear el balón entre los postes de gol
            desde cualquier lugar del campo. Si tienen éxito, reciben 3 puntos.
          </li>
          <li>
            <strong>Safety (S):</strong> Un safety ocurre cuando el equipo
            defensor tacklea al portador del balón en su propia zona de
            anotación, otorgándole 2 puntos al equipo defensor y también le da
            posesión del balón al cuadro atacante.
          </li>
        </ul>

        <h2 id={"anchor-2"} className="codere-green">
          Sistema de conferencias
        </h2>

        <p>
          En la NFL (National Football League), los sistemas de conferencias se
          refieren a la estructura organizativa de la liga, que se divide en dos
          conferencias principales: la Conferencia Nacional (NFC) y la
          Conferencia Americana (AFC). Cada conferencia tiene 16 equipos, se
          subdivide en cuatro divisiones, y los equipos compiten en su
          respectiva conferencia y división para avanzar a los playoffs y
          eventualmente llegar al Super Bowl.
        </p>

        <h3 className="codere-green">Principales equipos en la actualidad</h3>
        <p>
          Dentro de los principales encontramos a los Kansas City Chiefs, Tampa
          Bay Buccaneers, Buffalo Bills, Green Bay Packers y Los Angeles Rams,
          que son conjuntos que tienen un registro positivo e histórico entre
          los demás equipos de este deporte estadounidense.
        </p>

        <h2 className="codere-green" id={"anchor-3"}>
          Super Bowl: El gran evento
        </h2>
        <p>
          El Super Bowl es el partido final y más importante de la National
          Football League (NFL) en los Estados Unidos. Se juega anualmente
          durante el primero o segundo domingo de febrero y enfrenta a los
          campeones de las conferencias de la NFL, la Conferencia Americana
          (AFC) y la Conferencia Nacional (NFC). Se considera uno de los eventos
          deportivos más grandes y populares del mundo, con gran audiencia
          televisiva y muchos espectáculos y eventos relacionados.
        </p>

        <h3 className="codere-green">El show de medio tiempo </h3>

        <p>
          El espectáculo de medio tiempo del Super Bowl es un evento musical que
          se lleva a cabo durante el descanso de este partido. Aquí se presentan
          artistas de renombre mundial, convirtiéndose en uno de los
          espectáculos más vistos y esperados del año.
        </p>

        <h2 className="codere-green" id={"anchor-4"}>
          Tipos de Apuestas de futbol americano
        </h2>
        <p>
          Aquí puedes apostar por el ganador del partido, el ganador de cada
          conferencia, el mejor jugador, la mejor defensa y hasta el entrenador
          destacado de la temporada regular. Cada una de estas opciones tiene su
          forma correcta de realizarse, así que entiende bien las reglas antes
          de hacer tu apuesta.
        </p>
        <h3 className="codere-green"> Super Bowl - Ganador </h3>
        <p>
          Solo dos equipos llegan a la final de la NFL. Estos conjuntos tienen
          varias{" "}
          <a href="https://www.codere.com.co/cuotas-deportivas">cuotas</a> para
          aumentar sus posibilidades de ganar. Además, durante el transcurso del
          juego, tienes la opción de apostar por tu favorito.
        </p>
        <h3 className="codere-green">
          {" "}
          Ganador Conferencia AFC (Play-Off Incluido)
        </h3>
        <p>
          La apuesta de ganador de la “Conferencia AFC” en la NFL se refiere a
          apostar por el equipo que se espera que gane la Conferencia Americana
          de Fútbol (AFC) en la National Football League (NFL) que se compone de
          16 equipos.
        </p>
        <h3 className="codere-green">
          Ganador Conferencia NFC (Play-Off Incluido){" "}
        </h3>
        <p>
          La apuesta de ganador de la conferencia NFC en la NFL es una apuesta
          en la que se pronostica qué equipo ganará la Conferencia Nacional de
          Fútbol Americano (NFC) y avanzará al Super Bowl. Esta también se
          compone de 16 equipos.
        </p>
        <h3 className="codere-green">MVP temporada regular </h3>
        <p>
          Esta opción es para el jugador más consistente durante toda la
          temporada del fútbol americano. Puedes encontrarla en la sección de
          “cuotas especiales” o “anticipadas NFL”
        </p>
        <h3 className="codere-green"> Jugador defensivo del año </h3>
        <p>
          Esta opción es para el jugador defensivo más constante en el fútbol
          americano durante todo el año. Y también podrás encontrarla en la
          sección de cuotas especiales” o “anticipadas NFL”
        </p>
        <h3 className="codere-green"> Entrenador del año </h3>
        <p>
          Siempre hay un premio para el estratega más destacado de la temporada
          regular del campeonato. Los jugadores pueden pronosticarlo en la
          opción de cuotas especiales.
        </p>
        <h3 className="codere-green">Crea Tu Apuesta </h3>
        <p>
          Esta es la nueva forma de realizar tus pronósticos deportivos; aquí
          puedes realizar múltiples combinaciones en tus partidos de NFL
          favoritos.
        </p>
        <p>Así de fácil puedes jugar con Crea tu apuesta:</p>
        <ol>
          <li>Así de fácil puedes jugar con Crea tu apuesta:</li>
          <li>Dale clic en la opción “Crea tu Apuesta”.</li>
          <li>Escoge los mercados que quieras.</li>
          <li>
            Cuando tengas todo listo, añade tus selecciones a tu boleto de
            apuestas.
          </li>
          <li>
            Indica la cantidad de dinero que desees apostar, confirma tu
            pronóstico, ¡y listo!
          </li>
        </ol>

        <h2 className="codere-green mt-4 mb-2" id={"anchor-5"}>
          Preguntas frecuentes
        </h2>
        <h3 className="codere-green"> ¿Qué significa +7.5 en NFL? </h3>
        <p>
          En la NFL, el "+7.5" se refiere a una línea de apuestas o spread, la
          ventaja o desventaja de un equipo en un partido. Cuando ves "+7.5"
          junto al nombre de un equipo, significa que ese equipo es el
          "underdog" o el equipo que se espera que pierda. Por otro lado, si ves
          "-7.5" junto al nombre de un equipo, significa que ese equipo es el
          favorito.
        </p>
        <h3 className="codere-green">
          {" "}
          ¿Cuál es el equipo favorito de la NFL en Colombia?{" "}
        </h3>
        <p>
          No hay un equipo específico de la NFL que sea considerado como el
          favorito en Colombia, ya que los fanáticos suelen apoyar a diferentes
          equipos según sus preferencias personales. Los equipos más populares
          suelen ser los que han tenido éxito en la liga, como los New England
          Patriots, con el colombo estadounidense Christian González, los Dallas
          Cowboys y los Kansas City Chiefs.
        </p>
        <LazyLoadImage
          className="lazyloadimg"
          src={`${prefix}/${props.flag ? "M" : "D"}-superbowl-3.webp`}
        />

        <Button
          href="https://m.codere.com.co/deportesCol/#/mso/RegistroCONewPage"
          className="mt-5 seo-reg-btn"
        >
          Regístrate
        </Button>
      </Container>
    </>
  );
};

export default CuotasNFL;
