import { Accordion, Table, Container } from "react-bootstrap";
import { LazyLoadImage } from "react-lazy-load-image-component";
import { useEffect } from "react";
import { CodereHelmet } from "../../../../App";
import { floating } from "../../helpers";
import { NavLink } from "react-router-dom";

const MLB = (props) => {
  const faq = [
    {
      question: "¿Quién ha ganado más Juegos de las Estrellas?",
      answer:
        "Históricamente, los equipos más exitosos en el All-Star Game de la NBA incluyen a los Los Angeles Lakers, los Boston Celtics, los Chicago Bulls y los Golden State Warriors.",
    },
    {
      question: "¿Cómo se gana un run line?",
      answer:
        "Un run line o handicap es cuando el equipo favorito ofrece una ventaja y el equipo menos favorito recibe una ventaja, para ganar un run line el equipo que ofrece la ventaja debe ganar o más carreras de las que ofrece, es decir si tiene un run line o handicap de - 1,5 el equipo debe ganar por 2 carreras o más, si recibe un run line de 1,5 el equipo debe ganar o en su defecto perder por menos de carreras para ganar la apuesta.",
    },
    {
      question: "¿Qué significa +1.5 en béisbol?",
      answer:
        "Significa que un equipo está recibiendo a su favor antes de hacer la apuesta una ventaja de 1.5 carreras, es decir, al comienzo del partido se encuentra 2 x 0 a favor de que obtiene o realiza esta apuesta. Por lo tanto, para no ganar la apuesta debe perder este equipo por 2 carreras o más.",
    },
    {
      question: "¿Qué es un handicap en béisbol?",
      answer:
        "Un handicap en béisbol es una ventaja o desventaja que otorgan los equipos, el favorito otorgará una ventaja, el no favorito recibirá una desventaja.",
    },
  ];

  const prefix =
    "https://www.codere.com.co/Colombia/images/seo-sport/eventos-dep/mlb";

  useEffect(() => {
    let json = {
      "@context": "https://schema.org",
      "@type": "FAQPage",
      mainEntity: [
        {
          "@type": "Question",
          name: "¿Quién ha ganado más juegos de las estrellas?",
          acceptedAnswer: {
            "@type": "Answer",
            text: "Históricamente, los equipos más exitosos en el All-Star Game de la NBA incluyen a los Los Angeles Lakers, los Boston Celtics, los Chicago Bulls y los Golden State Warriors.",
          },
        },
        {
          "@type": "Question",
          name: "¿Cómo se gana un run line?",
          acceptedAnswer: {
            "@type": "Answer",
            text: "Un run line o handicap es cuando el equipo favorito ofrece una ventaja y el equipo menos favorito recibe una ventaja, para ganar un run line el equipo que ofrece la ventaja debe ganar o más carreras de las que ofrece, es decir si tiene un run line o handicap de - 1,5 el equipo debe ganar por 2 carreras o más, si recibe un run line de 1,5 el equipo debe ganar o en su defecto perder por menos de carreras para ganar la apuesta.",
          },
        },
        {
          "@type": "Question",
          name: "¿Qué significa +1.5 en béisbol?",
          acceptedAnswer: {
            "@type": "Answer",
            text: "Significa que un equipo está recibiendo a su favor antes de hacer la apuesta una ventaja de 1.5 carreras, es decir, al comienzo del partido se encuentra 2 x 0 a favor de que obtiene o realiza esta apuesta. Por lo tanto, para no ganar la apuesta debe perder este equipo por 2 carreras o más.",
          },
        },
        {
          "@type": "Question",
          name: "¿Qué es un handicap en béisbol?",
          acceptedAnswer: {
            "@type": "Answer",
            text: "Un handicap en béisbol es una ventaja o desventaja que otorgan los equipos, el favorito otorgará una ventaja, el no favorito recibirá una desventaja.",
          },
        },
      ],
    };
    let title = "Apuestas Juego de las Estrellas » Bono de $200.000 | Codere®";
    let desc =
      "Conoce el mundo de las apuestas en baseball de las grandes ligas 🥎. Disfruta de las mejores cuotas deportivas en la MLB en Casa Codere.";

    let can =
      "https://www.codere.com.co/eventos-deportivos/apuestas-juego-de-las-estrellas";

    CodereHelmet(title, desc, can, [json]);
  }, []);

  return (
    <>
      {floating("Anotate un homerun", false)}
      <div
        className="top-bg-seo"
        style={{
          backgroundImage: `url(${prefix}/${
            props.flag ? "M" : "D"
          }-header.jpg)`,
          backgroundSize: "cover",
        }}
      ></div>
      <Container>
        <h1 className="text-center codere-green fw-normal mt-2 mb-4">
          Guía de apuestas para el Juego de las Estrellas de la MLB
        </h1>

        <h2 className="codere-green">
          Breve Historia del Juego de las Estrellas
        </h2>
        <p>
          Las Grandes Ligas, también conocidas como Ligas Mayores de Béisbol (en
          inglés Major League Baseball o MLB) son las ligas de béisbol
          profesional de mayor nivel en el mundo. Actualmente la MLB cuenta con
          30 equipos repartidos en la Liga Nacional y la Liga Americana,
          fundadas en 1901 y 1876 respectivamente. Los campeones de liga se
          enfrentan en la Serie Mundial al mejor de siete partidos.
        </p>
        <p>
          El Juego de Estrellas de las Grandes Ligas de Béisbol (Major League
          Baseball All-Star Game) es un{" "}
          <NavLink to="/eventos-deportivos">evento deportivo</NavLink> que se
          juega de manera anual entre los mejores jugadores de la Liga Nacional
          y la Liga Americana. El Juego de las Estrellas generalmente tiene
          lugar a mediados de julio y marca la mitad de la campaña de las
          Grandes Ligas.
        </p>
        <LazyLoadImage
          className="lazyloadimg"
          src={`${prefix}/${props.flag ? "M" : "D"}-Baseball-1.jpg`}
        />

        <h3 className="codere-green mt-4 mb-2">
          ¿Cómo se elige la sede del Juego de las Estrellas?
        </h3>
        <p>
          La sede es elegida por las Grandes Ligas y tradicionalmente alterna
          entre ambas ligas participantes. (Esta tradición se rompió en el 2007,
          cuando los Gigantes de San Francisco fueron los anfitriones para el
          Juego de Estrellas de ese año. Los Piratas de Pittsburgh fueron la
          sede del 2006).
        </p>

        <p>
          El "equipo de la casa" es la liga a la cual pertenece el equipo del
          estadio donde se juega. Los criterios de elección son subjetivos, pero
          en la mayoría de los casos, ciudades que tienen nuevos parques y
          aquellas que no han tenido un juego en ellas en mucho tiempo tienden a
          ser las más probables.
        </p>

        <p>
          Desde los años 2015 al 2018, cuatro equipos de la Liga Nacional, Rojos
          de Cincinnati, Padres de San Diego, Marlins de Miami y Nacionales de
          Washington fueron sedes. Los Indios de Cleveland fue el escenario del
          Juego de Estrellas del 2019, primera vez desde 2014 que un equipo de
          la Liga Americana realiza el clásico de mitad de temporada. En el 2020
          Los Dodgers de Los Ángeles iba a organizar el Juego de Estrellas, pero
          fue cancelado por primera vez desde 1945, y le otorgaron el juego
          anual para el 2022, después que los Bravos de Atlanta organicen el
          Juego de Estrellas del 2021.
        </p>

        <h3 className="codere-green mt-4 mb-2">
          ¿Cuándo se lleva a cabo el Juego de las Estrellas?
        </h3>
        <p>
          El "Juego de las Estrellas" de la NBA, conocido como el "All-Star
          Game", generalmente se lleva a cabo a mediados de febrero. Este evento
          reúne a los mejores jugadores de la NBA en un apasionante encuentro
          que incluye varios concursos y actividades especiales.
        </p>

        <h3 className="codere-green mt-4 mb-2">
          ¿Qué Jugadores participan en el Juego de las Estrellas?
        </h3>
        <p>
          El All-Star Game de la NBA ha contado con numerosos jugadores
          históricos a lo largo de los años. Algunos de los más destacados
          incluyen a Michael Jordan, LeBron James, Kobe Bryant, Magic Johnson,
          Larry Bird, Kareem Abdul-Jabbar, Shaquille O'Neal, Tim Duncan, Wilt
          Chamberlain, Bill Russell, Oscar Robertson y Julius Erving, entre
          otros.
        </p>

        <LazyLoadImage
          className="lazyloadimg"
          src={`${prefix}/${props.flag ? "M" : "D"}-Baseball-2.jpg`}
        />
        <h2 className="codere-green mt-4 mb-2">Tipo de apuestas en la MLB</h2>
        <p>
          En Codere te enseñamos que tipos de{" "}
          <a
            href="https://m.codere.com.co/deportesCol/#/HomePage"
            rel="nofollow"
          >
            apuestas deportivas en línea
          </a>{" "}
          puedes escoger para partidos de la MLB
        </p>
        <ul>
          <li>1,2 Ganado el partido</li>
          <li>1,2 a al quinto, innis quien sera el ganador al 5 innis. </li>
          <li>Carreras en el partido; Más o Menos</li>
          <li>Carreras al quinto innnis; Más o Menos</li>
          <li>Local y más o menos puntos</li>
          <li>Visitante y más o menos puntos. </li>
        </ul>

        <h3 className="codere-green mt-4 mb-2">Ejemplo de apuesta en Codere</h3>
        <p>
          Handicap -1.5 y más o menos carreras: Esta apuesta consiste en indicar
          si el equipo favorito ganará por 2 carreras o más y si realizarán más
          o menos carreras de las estipuladas.
        </p>
        <p>
          Ejemplo: Si mi apuesta es San Francisco handicap -1.5 y más de 8,5
          para ganar esta apuesta San Francisco debe ganar el partido por 2
          carreras o más y entre los dos equipos debes hacer 9 carreras o más.{" "}
        </p>

        <Table striped bordered hover size="sm" variant="dark">
          <thead>
            <tr>
              <th></th>
              <th>SFG – 1.5</th>
              <th>DET + 1.5</th>
            </tr>
          </thead>
          <tbody>
            <tr>
              <td>Más 8.5</td>
              <td>3,90 </td>
              <td>3,45</td>
            </tr>
            <tr>
              <td>Menos 8.5</td>
              <td>3,50</td>
              <td>3,10</td>
            </tr>
          </tbody>
        </Table>

        <div className="mt-4 mb-4">
          <h2 className="codere-green">
            Preguntas frecuentes sobre apuestas MLB
          </h2>
          <Accordion>
            {faq.map((item, k) => (
              <Accordion.Item eventKey={k} key={k}>
                <Accordion.Header as={"h3"}>{item.question}</Accordion.Header>
                <Accordion.Body as={"p"}>{item.answer}</Accordion.Body>
              </Accordion.Item>
            ))}
          </Accordion>
        </div>

        <LazyLoadImage
          className="lazyloadimg"
          src={`${prefix}/${props.flag ? "M" : "D"}-Baseball-3.jpg`}
        />
      </Container>
    </>
  );
};

export default MLB;
