import {
  Accordion,
  Button,
  Container,
  Dropdown,
  Image,
  InputGroup,
  Nav,
  Navbar,
} from "react-bootstrap";
import React, { useState } from "react";
import { elastic as Menu } from "react-burger-menu";
import {
  Arrowrightw,
  Deportes,
  Withdrawal,
  Envivo,
  Omni,
  Promociones,
  CasinoMenuIcon,
  Directo,
  Crown,
  ArrowDown,
} from "../icons";
import { SPEEDSIZE_PREFIX } from "../App";
import { Link } from "react-router-dom";
import { observer } from "mobx-react";
import myStore from "../mobX/Store";
const Header = observer(() => {
  const LOGO =
    `${SPEEDSIZE_PREFIX}https://www.codere.com.co/_catalogs/masterpage/codere/images/splash/SponsorsLogoGrey.png`;

  // Hooks

  const [onShow, setOnShow] = useState("");
  const [hamburger, setHamburger] = useState(false);
  const hamburgerHandler = () => {
    hamburger ? setOnShow("") : setOnShow("open");
    setHamburger(!hamburger);
  };

  const seo_menu = [
    {
      name: "Casino",
      path: "/casino",
    },
    {
      name: "Casino en Vivo",
      path: "/casino/casino-en-vivo",
    },
    {
      name: "Ruleta",
      path: "/casino/ruleta",
    },
    {
      name: "Slots",
      path: "/casino/slots",
    },
    {
      name: "Blackjack",
      path: "/casino/blackjack",
    },
    {
      name: "Jackpots",
      path: "/casino/jackpots",
    },
    {
      name: "Baccarat",
      path: "/casino/baccarat",
    },
    {
      name: "Video Bingo",
      path: "/casino/video-bingo",
    },
    {
      name: "Eventos deportivos",
      path: "/eventos-deportivos",
    },
    {
      name: "Cuotas deportivas",
      path: "/cuotas-deportivas",
    },
  ];

  const menu = [
    {
      name: "Deportes",
      url: "https://m.codere.com.co/deportesCol/#/HomePage",
      icon: Deportes,
    },
    {
      name: "Directo",
      url: "https://m.codere.com.co/deportesCol/#/DirectosPage",
      icon: Directo,
    },
    {
      name: "Crown",
      url: "https://m.codere.com.co/deportesCol/#/casino/SlotsPage?lobby=Colombia",
      icon: Crown,
    },
    {
      name: "Casino",
      url: "https://m.codere.com.co/deportesCol/#/casino/CasinoPage?lobby=Colombia",
      icon: CasinoMenuIcon,
    },
    {
      name: "Casino en vivo",
      url: "https://m.codere.com.co/deportesCol/#/casino/CasinoEnVivoPage?lobby=EnVivoCO",
      icon: Envivo,
    },
    {
      name: "Promociones",
      url: "https://m.codere.com.co/deportesCol/#/mso/PromotionsPage",
      icon: Promociones,
    },
    {
      name: "Locales",
      url: "https://m.codere.com.co/deportesCol/#/mso/NearestLocalPage",
      icon: Omni,
    },
    {
      name: "Recargas Y Retiros",
      url: "/ayuda/deposito-en-efectivo",
      icon: Withdrawal,
    },
  ];

  return (
    <>
      <Navbar className="header_nav stroke" variant="dark">
        {myStore.flag ? (
          <div id="father">
            <div id="outer-container">
              <Menu
                id="elastic"
                left
                customCrossIcon={false}
                pageWrapId={"page-wrap"}
                outerContainerId={"outer-container"}
                burgerButtonClassName={onShow}
                isOpen={hamburger}
                onOpen={hamburgerHandler}
                onClose={hamburgerHandler}
              >
                {menu.map((item, k) => (
                  <a
                    href={item.url}
                    rel="nofollow"
                    key={k}
                    className="d-flex justify-content-between align-items-center"
                  >
                    <span>
                      <item.icon />
                      {item.name}
                    </span>
                    <Arrowrightw />
                  </a>
                ))}

                {/*mobile*/}
                <Accordion className="hamb-accordion">
                  <Accordion.Item eventKey="0">
                    <Accordion.Header as="span">
                      <span className="codere-green">¿Cómo jugar?</span>
                    </Accordion.Header>
                    <Accordion.Body>
                      <ul>
                        {seo_menu.map((item, k) => (
                          <li key={k} className="p-1">
                            <Link
                              className="d-flex justify-content-between p-1"
                              to={item.path}
                              onClick={hamburgerHandler}
                            >
                              {item.name}
                              <Arrowrightw />
                            </Link>
                          </li>
                        ))}
                      </ul>
                    </Accordion.Body>
                  </Accordion.Item>
                </Accordion>
              </Menu>
              <main id="page-wrap"></main>
            </div>
            <div className="header-menu">
              <a
                href="https://m.codere.com.co/deportesCol/#/HomePage"
                rel="nofollow"
              >
                <Image
                  src={LOGO}
                  style={myStore.segmented ? { left: "0" } : { left: "25px" }}
                  alt="Logo Casa de Apuestas Codere - Real Madrid "
                />
              </a>
              <div id="header_buttons">
                <Button
                  href={myStore.acceder}
                  className="acceder-button header_btn"
                  rel="nofollow"
                >
                  Acceder
                </Button>
                {!myStore.segmented && (
                  <Button
                    href={myStore.regis}
                    className="registrate-button header_btn"
                    rel="nofollow"
                  >
                    Regístrate
                  </Button>
                )}
              </div>
            </div>
          </div>
        ) : (
          <Container>
            <InputGroup className="header-group-left">
              <a
                href="https://m.codere.com.co/deportesCol/#/HomePage"
                rel="nofollow"
              >
                <img
                  src={LOGO}
                  alt="Logo Casa de Apuestas Codere - Real Madrid "
                />
              </a>

              <Nav className="me-auto">
                {menu.map((item, k) => (
                  <Nav.Link href={item.url} rel="nofollow" key={k}>
                    <span className="text-uppercase">{item.name}</span>
                  </Nav.Link>
                ))}
              </Nav>
            </InputGroup>
            <div className="header-group-right">
              <Dropdown>
                <Dropdown.Toggle className="header_btn">
                  <ArrowDown style={{ maxHeight: "1.4em" }} />
                </Dropdown.Toggle>
                <Dropdown.Menu>
                  {seo_menu.map((item, index) => (
                    <Dropdown.Item as={Link} to={item.path} key={index}>
                      {item.name}
                    </Dropdown.Item>
                  ))}
                </Dropdown.Menu>
              </Dropdown>
              <Button
                href={myStore.acceder}
                // onClick={handleShow}
                className="acceder-button header_btn"
                rel="nofollow"
              >
                Acceder
              </Button>
              {!myStore.segmented && (
                <Button
                  href={myStore.regis}
                  className="registrate-button header_btn"
                  rel="nofollow"
                >
                  Regístrate
                </Button>
              )}
            </div>
          </Container>
        )}
      </Navbar>
    </>
  );
});

export default Header;
