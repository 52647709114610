import { Button, Container } from "react-bootstrap";
import { LazyLoadImage } from "react-lazy-load-image-component";
import { CodereHelmet } from "../../../../App";
import { useEffect } from "react";
import TableOfContents from "../../TableOfContents";
import { floating } from "../../helpers";

const CuotasFormula = (props) => {
  const prefix =
    "https://www.codere.com.co/Colombia/images/seo-sport/cuotas-deportivas";

  useEffect(() => {
    let json = {
      "@context": "https://schema.org",
      "@type": "FAQPage",
      mainEntity: [
        {
          "@type": "Question",
          name: "¿Quién ha ganado más mundiales de baloncesto?",
          acceptedAnswer: {
            "@type": "Answer",
            text: "Estados Unidos es el país que lidera el medallero general del Mundial. En la era profesional, el equipo se consagró campeón olímpico en 1992, 1996, 2000, 2008, 2012, 2016 y 2020. ",
          },
        },
        {
          "@type": "Question",
          name: "¿Cuándo es el próximo Mundial de Baloncesto?",
          acceptedAnswer: {
            "@type": "Answer",
            text: "La Copa Mundial de Baloncesto se llevará a cabo el viernes 25 de agosto de 2023 hasta el domingo 10 de septiembre y se celebrará conjuntamente en Filipinas, Japón e Indonesia.",
          },
        },
      ],
    };
    let title = "Apuestas para la Fórmula 1 » Bono de $200.000 | Codere®";
    let desc =
      "Una guía para que conozcas lo mejor de la Fórmula 1 y por qué este deporte levanta fibras de emoción entre los apostadores en el mundo.";

    let can = "https://www.codere.com.co/cuotas-deportivas/apuestas-formula1";

    CodereHelmet(title, desc, can, [json]);
  }, []);
  const table_of_contents_list = [
    {
      title: "El Gran Premio de la F1 ",
      id: "anchor-1",
    },
    {
      title: "Apuestas y cuotas en la F1",
      id: "anchor-2",
    },
    {
      title: "Preguntas frecuentes sobre el Grand Prix ",
      id: "anchor-3",
    },
  ];
  return (
    <>
      {floating("disfruta la adrenalina", false)}

      <div
        className="top-bg-seo"
        style={{
          backgroundImage: `url(${prefix}/${
            props.flag ? "M" : "D"
          }-formula-header.webp)`,
          backgroundSize: "cover",
        }}
      ></div>
      <Container>
        <h1 className="text-center codere-green fw-normal mt-4">
          Conoce las apuestas de F1 y emociónate toda velocidad
        </h1>
        <p>
          En Codere te damos una guía para que conozcas lo mejor de la Fórmula 1
          y por qué este deporte levanta fibras de emoción entre los apostadores
          en el mundo.
        </p>

        <TableOfContents table={table_of_contents_list} />

        <h2 id={"anchor-1"} className="codere-green">
          El Gran Premio de la F1
        </h2>
        <p>
          Un <strong> Gran Premio de Fórmula 1 </strong> es la máxima expresión
          del automovilismo de competición. Cada evento es una carrera
          individual que forma parte del Campeonato Mundial de Fórmula 1,
          organizado por la Federación Internacional del Automóvil (FIA).
        </p>

        <h3 className="codere-green">Clasificaciones </h3>

        <p>
          La gran carpa del automovilismo mundial tiene dentro cada Gran Premio
          (GP), rondas de preparación y clasificación divididas en:
          Entrenamientos Libres (Libres 1, Libres 2 y Libres 3) que duran de 60
          a 90 minutos, Clasificación (Q1, Q2 y Q3) donde en 18, 15 y 12 minutos
          cada piloto obtiene su lugar en la competencia en el país
          seleccionado, para obtener la Pole Position (primer lugar para la
          carrera que dura 2 horas aproximadamente).
        </p>
        <h3 className="codere-green">
          {" "}
          ¿Cuáles son los circuitos más importantes?{" "}
        </h3>

        <p>
          Esta competencia tiene varios circuitos importantes sin embargo
          destacan estos por su historia y complejidad: <br />
          Dentro de los circuitos históricos podríamos enmarcar a:
        </p>
        <ul>
          <li>Monza (Italia)</li>
          <li>Spa-Francorchamps (Bélgica)</li>
          <li>ilverstone (Inglaterra)</li>
        </ul>

        <p>
          Mientras que en la categoría de complejos o desafiantes podremos
          encontrar a los circuitos de: Suzuka (Japón), Interlagos (Brasil) o
          Mónaco (Mónaco); con una mención especial para Nürburgring (Alemania)
          considerado como leyenda dentro del certamen.
        </p>

        <h3 className="codere-green"> Sistema de puntuación </h3>
        <p>
          En el sistema de puntuación vigente, encontraremos una división de 25
          puntos en una escala del primero al décimo lugar, quien finalmente
          obtiene un punto. Sin embargo, también se dan puntos libres a los
          conductores que realicen: Vuelta rápida y Carreras al sprint (las
          cuales el primero obtiene 8 puntos y el octavo solo queda con un
          punto).
        </p>

        <h2 id={"anchor-2"} className="codere-green">
          Apuestas y cuotas en la F1
        </h2>
        <h3 className="codere-green">
          {" "}
          ¿Cómo funcionan las cuotas de apuestas en F1?{" "}
        </h3>

        <p>
          El funcionamiento básico se asemeja al de las apuestas en otros
          deportes, pero con algunas particularidades propias de la F1 tales
          como:
        </p>
        <p>
          <strong> Ganador de la Carrera: </strong>
          aquí puedes predecir qué corredor se alzará con la victoria al final
          de la carrera. Es una apuesta directa y emocionante que te permite
          respaldar a tu piloto favorito.
        </p>
        <p>
          <strong>Podio (los primeros tres lugares):</strong>
          con esta opción tienes la oportunidad de apostar por los tres pilotos
          que ocuparán las primeras plazas al final de la carrera. Es una opción
          más flexible que el ganador de la carrera y te permite cubrir
          diferentes escenarios.
        </p>
        <p>
          <strong>Campeonato mundial: </strong>
          esta apuesta te permite respaldar al piloto o escudería que crees que
          se llevará el título al final de la temporada. Es una apuesta a largo
          plazo que añade más emoción a toda la temporada de carreras.
        </p>
        <p>
          <strong>Vuelta rápida: </strong>
          apuesta por el piloto que crees que dará la vuelta más rápida durante
          la carrera. Esta opción es ideal para aquellos que quieren agregar
          intensidad a cada vuelta de la carrera.
        </p>
        <p>
          <strong>
            Cantidad de abandonos o la posición del piloto en un equipo
            determinado:
          </strong>
          Con Codere y esta opción puedes apostar por el número de pilotos que
          abandonarán la carrera o la posición específica en la que terminará un
          piloto dentro de un equipo determinado; esta te permite apostar en
          aspectos más específicos y detallados de la carrera.
        </p>

        <h3 className="codere-green"> Mejores pilotos en la actualidad </h3>
        <p>
          En el mundo de la Fórmula 1, se destacan pilotos como Max Verstappen y
          Charles Leclerc, quienes son jóvenes talentos. También figura Carlos
          Sainz Jr, uno de los cuatro pilotos españoles que han alcanzado el
          podio en Fórmula 1. George Russell y Lewis Hamilton son figuras
          reconocidas; Russell por su ascenso meteórico y Hamilton, piloto
          histórico con múltiples campeonatos en su haber. Estos pilotos
          continúan elevando el nivel de competencia y emocionando a los
          fanáticos en cada carrera.
        </p>
        <h3 className="codere-green"> Mejores escuderías </h3>
        <p>
          Las escuderías de Fórmula 1 se pueden dividir en dos categorías: Top 3
          histórico y Top 3 de rendimiento actual.
        </p>
        <p>
          En el Top 3 histórico, destacan Ferrari, Williams y McLaren,
          reconocidas por su legado de campeonatos y pilotos legendarios. En el
          Top 3 de rendimiento actual, se encuentran Red Bull, Ferrari y
          McLaren. Red Bull ha dominado recientemente, Ferrari muestra un
          resurgimiento competitivo, y McLaren ha regresado fuerte a la
          contienda de los primeros puestos.
        </p>

        <h2 id={"anchor-3"} className="codere-green mt-4 mb-2">
          Preguntas frecuentes sobre el Grand Prix
        </h2>
        <p>
          Aquí conocerás cuales son las preguntas más frecuentes sobre el mundo
          de las apuestas y la fórmula 1.
        </p>

        <h3 className="codere-green ">
          ¿Cuál es el equipo más ganador de la F1?
        </h3>
        <p>
          Dentro de los equipos más ganadores de esta competencia del
          automovilismo podremos encontrar a Ferrari, cuya escudería ostenta 16
          títulos en su palmarés.
        </p>
        <h3 className="codere-green">¿Qué es la carrera sprint en f1? </h3>
        <p>
          Implantada recientemente. Se trata de una carrera corta, de
          aproximadamente 100 kilómetros (unas 25-30 vueltas) que se disputa el
          sábado por la tarde y que determina la parrilla de salida para el Gran
          Premio del domingo. Se estima de 25 a 30 vueltas y dura unos 30
          minutos aproximadamente.
        </p>

        <LazyLoadImage
          className="lazyloadimg"
          src={`${prefix}/${props.flag ? "M" : "D"}-formula1-2.webp`}
        />

        <Button
          href="https://m.codere.com.co/deportesCol/#/mso/RegistroCONewPage"
          className="mt-5 seo-reg-btn"
        >
          Regístrate
        </Button>
      </Container>
    </>
  );
};

export default CuotasFormula;
