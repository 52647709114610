import { Button, Container } from "react-bootstrap";
import { LazyLoadImage } from "react-lazy-load-image-component";
import { CodereHelmet } from "../../../../App";
import { useEffect } from "react";
import TableOfContents from "../../TableOfContents";
import { floating } from "../../helpers";

const UFC = (props) => {
  const prefix =
    "https://www.codere.com.co/Colombia/images/seo-sport/cuotas-deportivas";

  useEffect(() => {
    let json = {
      "@context": "https://schema.org",
      "@type": "FAQPage",
      mainEntity: [
        {
          "@type": "Question",
          name: "¿Dónde ver el calendario de peleas de la UFC?",
          acceptedAnswer: {
            "@type": "Answer",
            text:
              "El calendario completo de la UFC lo podrás encontrar en el sitio web oficial de la Ultimate Fighting Championship o en la" +
              " aplicación móvil de la organización.",
          },
        },
        {
          "@type": "Question",
          name: "¿Dónde ver las peleas de UFC en Colombia?",
          acceptedAnswer: {
            "@type": "Answer",
            text: "Tanto para Latinoamérica como para Colombia,los combates se pueden ver a través de las plataformas de streaming de la UFC,así como también están disponibles a través de plataformas por suscripción que tengan los derechos de transmisión.",
          },
        },
      ],
    };
    let title = "Aprende de Apuestas en UFC » Bono de $200.000 | Codere®";
    let desc =
      "Aquí te ayudamos a entender como funcionan las apuestas y para UFC 🤼 con Codere, tu destino de emociones y victorias en las artes marciales mixtas.";

    let can = "https://www.codere.com.co/cuotas-deportivas/apuestas-ufc";

    CodereHelmet(title, desc, can, [json]);
  }, []);
  const table_of_contents_list = [
    {
      title: "¿Qué es la UFC? ",
      id: "anchor-1",
    },
    {
      title: "Top de peleadores (masculino). ",
      id: "anchor-2",
    },
    {
      title: "Top de peleadoras (femenino).",
      id: "anchor-3",
    },
    {
      title: "¿Cómo funcionan las apuestas en la UFC de Codere? ",
      id: "anchor-4",
    },
    {
      title: "Tips para apostar",
      id: "anchor-5",
    },
    {
      title: "Preguntas frecuentes sobre las apuestas en la UFC ",
      id: "anchor-6",
    },
  ];
  return (
    <>
      {floating("Desafía tus pronósticos", false)}
      <div
        className="top-bg-seo"
        style={{
          backgroundImage: `url(${prefix}/${
            props.flag ? "M" : "D"
          }-ufc-header.jpg)`,
          backgroundSize: "cover",
        }}
      ></div>
      <Container>
        <h1 className="text-center codere-green fw-normal mt-4">
          Apuesta la UFC y aumenta la emoción con Codere
        </h1>

        <p>
          En esta guía podrás conocer con más detalle todo lo relacionado con la
          UFC y como podrás darles más emoción a tus pronósticos deportivos en
          este deporte de combate.
        </p>
        <TableOfContents table={table_of_contents_list} />
        <h2 id={"anchor-1"} className="codere-green mt-4">
          ¿Qué es la UFC?
        </h2>
        <p>
          La UFC, o Ultimate Fighting Championship, es una organización de artes
          marciales mixtas (MMA), considerada como la principal promotora de
          este deporte que ha tomado fuerza con el paso de los años. En estas
          peleas, los competidores combinan técnicas de diversas disciplinas de
          combate, como boxeo, kickboxing, lucha libre, jiu-jitsu brasileño y
          judo, creando un estilo de lucha dinámico y emocionante.
        </p>

        <h3 id={"anchor-2"} className="codere-green mt-4">
          Top de Peleadores (masculino)
        </h3>
        <ol>
          <li>
            <b>Kamaru Usman:</b> De origen nigeriano, Usman es el actual campeón
            del peso welter y ostenta un récord de 20-1-0 y hoy por hoy se le
            considera como uno de los peleadores más fuertes de la UFC en la
            historia, gracias a su estilo completo en el octágono.
          </li>
          <li>
            <b>Alexander Volkanovski:</b> Al luchador australiano, se le
            considera dominante en el campo de batalla y se ha hecho sentir
            gracias a sus números de 25-1-0 en la categoría de peso pluma.
          </li>
          <li>
            <b> Israel Adesanya:</b> Sus estadísticas de 23-1-0 lo sitúan como
            el peleador nigeriano más creativo y letal de la UFC, en la
            categoría de peso medio.
          </li>
        </ol>
        {/*<LazyLoadImage*/}
        {/*	className="lazyloadimg"*/}
        {/*	src={`${prefix}/${props.flag?"M":"D"}-UFC-1.jpg`}*/}
        {/*/>*/}

        <h3 id={"anchor-3"} className="codere-green mt-4 mb-2">
          Top de Peleadoras (femenino)
        </h3>

        <ol>
          <li>
            <b>Amanda Nunes:</b> La brasileña es campeona en 2 divisiones de las
            artes marciales mixtas, el peso gallo y el peso pluma, con un récord
            de 21-5, proyectándose como una leyenda viviente de la disciplina.
          </li>
          <li>
            <b>Valentina Shevchenko:</b> Proveniente de Kirguistán, esta
            luchadora se destaca en la categoría del peso mosca y sus números
            son de 23-3.
          </li>
          <li>
            <b>Julianna Peña:</b> La estadounidense de orígenes venezolanos es
            una de las peladoras más fuertes gracias a su juego de golpes y
            sumisiones, permitiéndole no solo tener el récord de 11-4 sino
            también el campeonato interino del peso pluma.
          </li>
        </ol>

        <h3 id={"anchor-5"} className="codere-green mt-4 mb-2">
          Tips para hacer pronósticos en la UFC
        </h3>

        <p>
          Además de tener claro que el juego es diversión y que se debe hacer
          con moderación, debes seguir estas recomendaciones:
        </p>
        <ul>
          <li>
            Investigar a los peleadores, estudiar los estilos de lucha y las
            estadísticas de estos.
          </li>
          <li>Comprender muy bien las líneas de apuesta.</li>
          <li>Comprender muy bien las líneas de apuesta.</li>
        </ul>
        <h2 id={"anchor-4"} className="codere-green mt-4 mb-2">
          ¿Cómo funcionan las apuestas de la UFC con Codere?
        </h2>
        <p>
          En nuestra oferta para la UFC figuran dos tipos de apuesta con los que
          podrás vivir la experiencia de las artes marciales mixtas desde tu
          celular:
        </p>
        <h3 className="codere-green mt-4 mb-2">Ganador del Combate</h3>
        <p>
          Aquí se elige al que creas ganador de la pelea. Es tu oportunidad de
          respaldar a tu favorito y anticipar quién se alzará triunfante en el
          enfrentamiento.
        </p>
        <h3 className="codere-green mt-4 mb-2">
          Más o menos cantidad de asaltos
        </h3>
        <p>
          Aquí podrás anticipar la duración del combate en términos de asaltos.
          El promedio de asaltos es de 2,5 rounds, lo que permite al jugador
          pronosticar la cantidad de asaltos que durará el combate.
        </p>
        <h2 id={"anchor-6"} className="codere-green mt-4 mb-2">
          Preguntas frecuentes sobre el UFC
        </h2>
        <p>
          Aquí podrás encontrar las preguntas más frecuentes sobre apostar en la
          UFC con Codere.
        </p>
        <h3 className="codere-green mt-4 mb-2">
          ¿Dónde ver el calendario de peleas de la UFC?
        </h3>
        <p>
          El calendario completo de la UFC lo podrás encontrar en el sitio web
          oficial de la Ultimate Fighting Championship o en la aplicación móvil
          de la organización.
        </p>
        <h3 className="codere-green mt-4 mb-2">
          ¿Dónde ver las peleas de UFC en Colombia?
        </h3>
        <p>
          Tanto para Latinoamérica como para Colombia, los combates se pueden
          ver a través de las plataformas de streaming de la UFC, así como
          también están disponibles a través de plataformas por suscripción que
          tengan los derechos de transmisión.
        </p>

        <LazyLoadImage
          className="lazyloadimg"
          src={`${prefix}/${props.flag ? "M" : "D"}-UFC-2.jpg`}
        />

        <Button
          href="https://m.codere.com.co/deportesCol/#/mso/RegistroCONewPage"
          className="mt-5 seo-reg-btn"
        >
          Regístrate
        </Button>
      </Container>
    </>
  );
};

export default UFC;
