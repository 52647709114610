import {Accordion,Button,Col,Row,Table} from "react-bootstrap";
import React from "react";
import {NavLink} from "react-router-dom";

var questions=[
	"¿Cuáles son los tipos de apuestas deportivas?",
	"¿Cuánto es lo mínimo en dinero para poder apostar?",
	"¿Cómo hacer pronósticos de fútbol?",
	"¿Cómo ganar en apuestas deportivas?",
];
var answers=[
	"El mejor mercado para apostar o el más rentable es el 1,2 lo que quiere decir que gana local o gana visitante, esto sucede en deportes como Baloncesto, Béisbol o Tenis. Para el caso del fútbol es diferente, existe la opción 1X2, que incluye igualmente, gana local o gana visitante y el empate.",
	"En Codere, la casa de apuestas en Colombia más bacana, el dinero mínimo que deberás tener para apostar es de $1.000 pesos colombianos, con este monto, podrás apostar a cualquier deporte, ya sea baloncesto, tenis, béisbol, fútbol, entre otros que se pueden encontrar en la plataforma.",
	"Existen diferentes tipos de herramientas para que puedas hacer pronósticos de fútbol, por ejemplo, aplicaciones con datos y estadísticas, e incluso páginas web que registran el rendimiento de los equipos.",
	"Las apuestas combinadas deportivas te pueden hacer ganar más dinero, ya que todas las selecciones que hagas multiplican la posibilidad de ganar dependiendo de lo que apuestes, pero, así como puedes ganar más, el nivel de complejidad aumenta. Recuerda que, si fallas una selección, fallarás la apuesta.",
];
function Content() {
  return (
    <div className="content-container inicioPageTextData">
      <Row>
        <Col md={4} xs={12}>
          <h1>Casa de Apuestas Deportivas en Línea: la mejor en Colombia </h1>
          <p>
            Vive una experiencia emocionante en la mejor casa de apuestas
            deportivas en línea de Colombia. Pon a prueba tus conocimientos y
            aprende estrategias efectivas para ganar dinero real en un entorno
            seguro y profesional. Todas nuestras apuestas son seguras, y
            contamos con presencia en varios países.
          </p>
          <p>
            En Codere, puedes realizar apuestas en una amplia variedad de
            deportes, equipos y jugadores desde tu móvil u ordenador. Todo está
            a tu disposición para vivir experiencias extraordinarias.
          </p>
          <h2>Consejos para tus Apuestas Deportivas en Codere </h2>
          <p>
            Las apuestas deportivas son emocionantes y accesibles. Con Codere
            tienes la oportunidad de aprender de profesionales y acelerar tu
            proceso. Ofrecemos varios tipos de apuestas, desde sencillas,
            combinadas incluyendo Parlay, una opción popular en Colombia que
            puede generar grandes ganancias o la nueva “Crea tu Apuesta” donde
            podrás crear tu ticket con dos o más selecciones del mercado de una
            forma dinámica y entretenida.
          </p>

					<h3>Entender a los Equipos</h3>
					<p>
						El conocimiento es esencial para lograr apuestas exitosas. Debes
						conocer a fondo los equipos, los jugadores y las circunstancias que
						rodean tus deportes favoritos.
					</p>
				</Col>

				<Col md={4} xs={12}>
					<h3>Conocer Tus Probabilidades</h3>
					<p>
						Tener un plan es esencial. Es importante que determines cuánto
						dinero estás dispuesto a apostar y cómo distribuirlo en función de
						las apuestas y las cuotas que te llaman la atención.
					</p>
					<h3>Tener una Estrategia </h3>
					<p>
						Adoptar una estrategia sólida a la hora de hacer tus pronósticos
						deportivos te permitirá controlar tus emociones y realizar apuestas
						de una manera más profesional
					</p>
					<h3>Apuesta de Manera Responsable y divierte </h3>
					<p>
						Comencemos recordando que las apuestas deben ser divertidas. Al
						igual que en cualquier juego, habrá momentos de victoria y otros de
						derrota. Aprende a celebrar tus éxitos y afrontar tus pérdidas con
						madurez.
					</p>
					<p>
						Apuesta siempre dentro de tus posibilidades financieras
						estableciendo tus límites. Puedes distribuir tu presupuesto de
						apuestas de acuerdo a tus preferencias.
					</p>
					<p>
						Considera el dinero apostado como gasto y no apuestes para recuperar
						pérdidas pasadas. Enfócate en el futuro y juega con precaución.
					</p>
					<p>
						Concéntrate en lo que conoces: tus deportes, equipos y jugadores
						favoritos. Evita adentrarte en terrenos desconocidos.
					</p>
					<h3>Apuesta seguro </h3>
					<p>
						Nuestra presencia en el mundo durante 40 años, con un crecimiento
						constante como multinacional, respalda todas nuestras operaciones,
						esto nos ha permitido adquirir un profundo conocimiento de la
						industria y ofrecer plataformas totalmente confiables y seguras.
					</p>
				</Col>
				<Col md={4} xs={12}>
					<p>
						En Colombia, operamos bajo la supervisión de Coljuegos, cumpliendo
						con toda la legislación vigente. Todas nuestras transacciones y
						plataformas cumplen con los más altos estándares de seguridad y
						privacidad de la industria.
					</p>
					<div>
						En conclusión:
						<ul>
							<li>Conoce tu deporte, equipos y momentos clave.</li>
							<li>Define tus herramientas y estrategias.</li>
							<li>
								Sigue tu plan con disciplina y mantén tus emociones bajo
								control.
							</li>
						</ul>
					</div>
					<h2>Vive la Experiencia del Casino Online en Codere </h2>
					<p>
						En Codere, ofrecemos mucho más. Es posible que ya conozcas nuestra
						amplia presencia en todo el territorio nacional a través de nuestras
						exclusivas salas de juegos, que incluyen Crown Casinos, Mundo
						Fortuna, Fantasía Royal y Star Casino Games. Ahora, te invitamos a
						descubrir nuestro emocionante{" "}
						<NavLink to={"/casino/casino-en-vivo"}>casino en vivo</NavLink>,
						donde podrás disfrutar de los mejores juegos desde la comodidad de
						tu computadora o dispositivo móvil.
					</p>
					<p>
						En nuestro <NavLink to={"/casino"}>casino online</NavLink>,
						encontrarás una amplia variedad de mesas y máquinas de juegos para
						satisfacer tus deseos: desde{" "}
						<NavLink to={"casino/blackjack"}>Blackjack</NavLink>,{" "}
						<NavLink to={"casino/baccarat"}>Baccarat</NavLink>, Poker y{" "}
						<NavLink to={"casino/ruleta"}>Ruleta</NavLink>, hasta Tragamonedas
						(slots), Video Bingo y mucho más. Puedes explorar nuestras salas
						virtuales y elegir el juego que más te divierta, siempre con la
						tranquilidad y seguridad que solo Codere puede proporcionar.
					</p>
					<p>
						Recuerda que siempre estamos preparando sorpresas, promociones,
						bonificaciones, premios y mucho más para ofrecerte una experiencia
						de casino excepcional. Estamos comprometidos en brindarte la mejor
						experiencia posible en juegos de azar y haremos todo lo que esté a
						nuestro alcance para que así sea.
					</p>
				</Col>
			</Row>

			<Table
				striped
				bordered
				hover
				variant="dark"
				className="responsive-table mt-4"
			>
				<tbody>
				<tr>
					<td>🏆 Inicio de operaciones</td>
					<td>1984</td>
				</tr>
				<tr>
					<td>⚽ Apuestas Deportivas</td>
					<td>
						Apuestas deportivas futbol en vivo, Apuestas Champions League,
						Apuestas NBA y más
					</td>
				</tr>
				<tr>
					<td>🎲 Licencia</td>
					<td>C1901 15 de noviembre de 2022 al 10 noviembre de 2025</td>
				</tr>
				<tr>
					<td>✔️ Moneda</td>
					<td>COP</td>
				</tr>
				<tr>
					<td>💰 Depósito mínimo</td>
					<td>$8.000 COP</td>
				</tr>
				<tr>
					<td>💸 Límites de pago</td>
					<td>desde $50.000 pesos hasta $1.500.000 pesos por transacción.</td>
				</tr>
				<tr>
					<td>💳 Formas de Pago</td>
					<td>
						Visa, MasterCard, PSE, PAyU, tpaga, efecty, Dimonex, Punto Red,
						Daviplata, Via Baloto
					</td>
				</tr>
				</tbody>
			</Table>

			<section id="acc-section" className="mt-5">
				<h2 className="bottomContentTitle">
					Preguntas frecuentes sobre apuestas deportivas en Codere
				</h2>

				<Accordion
					className="content-accordion"
					defaultActiveKey={["0"]}
					alwaysOpen
				>
					{answers.map((answer,index)=>{
						return (
							<Accordion.Item key={index} eventKey={index}>
								<Accordion.Header as="h3">
									<p>{questions[index]}</p>
								</Accordion.Header>
								<Accordion.Body>
									<p>{answer}</p>
								</Accordion.Body>
							</Accordion.Item>
						);
					})}
				</Accordion>

				<h3 className="bottomContentSpecialTitle mt-4">
					¡ANÍMATE Y DESCUBRE TODAS LAS VENTAJAS QUE TE OFRECE CODERE!
				</h3>
				<div className="bottomContentPromoTitle">
					La emoción del deporte y del casino al alcance de tu mano
				</div>
				<Button
					style={{margin:"auto",fontSize:"1.35rem"}}
					className="registrate-button mt-5"
				>
					Regístrate
				</Button>
			</section>
		</div>
	);
}

export default Content;
