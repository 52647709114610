import { Container, Button } from "react-bootstrap";
import TableOfContents from "../TableOfContents";
import { CodereHelmet } from "../../../App";
import { useEffect } from "react";
import LiveGamesDisplay from "../../LiveGamesDisplay";
import { dynamicTable, floating } from "../helpers";
import { SPEEDSIZE_PREFIX } from "../../../App";

export default function Jackpots(props) {
  // const game_prefix =
  //   "https://m.codere.com.co/deportesCol/#/casino/CasinoPage?lobby=Colombia&playgame=";

  useEffect(() => {
    let title = "Jackpots de Slots Online » Bono de $200.000 | Codere®";
    let desc =
      "Juega en los mejores Jackpots de Codere y gana el premio máximo de tragamonedas o slots 🎰. Vive la experiencia y prueba tu suerte online.";

    let can = "https://www.codere.com.co/casino/jackpots";

    CodereHelmet(title, desc, can, []);
  }, []);

  const top_par = {
    h1: "Jackpots de slots, el premio máximo",
    p: ["En este artículo aprenderás sobre:"],
  };

  // Table of Contents  \\
  const table_of_contents_list = [
    {
      title: "¿Qué es un jackpot y cuales son sus diferentes tipos?",
      id: "anchor-1",
    },
    {
      title: "Tu oportunidad con los jackpots es todos los días",
      id: "anchor-2",
    },
    {
      title: "4 consejos para agarrar un premio millonario en los jackpots",
      id: "anchor-3",
    },
  ];

  // Table

  const tableData = {
    headers: [],
    rows: [
      {
        id: 1,
        a1: "Jackpot fijos",
        a2: "Son slots en los cuales la cantidad en juego no tiene variación. Esta cantidad siempre se mantendrá sin importar si juegas más veces.",
      },
      {
        id: 2,
        a1: "Jackpot progresivo",
        a2: "Son los premios acumulados que pueden ir creciendo cada vez más. Estos Jackpots crecerán de acuerdo un porcentaje de la cantidad que tú sigas jugando en esta slot. ¡No pierdas de vista este tipo de Jackpots! ¡Relájate! Sabemos que hay una amplia variedad de juegos, por eso hemos creado una lista con la mejor selección de slots, juegos que su trama y temática te llevará a divertirte y ganar.",
      },
    ],
  };

  // Games Data
  const live_games = [
    {
      name: "PT Age of the Gods Rulerof the Seas",
    },
    {
      name: "PT Age of the Gods Epic Troy",
    },
    {
      name: "PT Age of the Gods Apollo Power",
    },
    {
      name: "PT Kingdoms Rise Captains Treasure",
    },
    {
      name: "PT Age of the Gods Rulers of Olympus",
    },
    {
      name: "HAB 5 Lucky Lions",
    },
    {
      name: "PGO Wild Frames",
    },
    {
      name: "HAB Panda Panda",
    },
    {
      name: "HAB Hot Hot Fruit",
    },
    {
      name: "HAB Fa Cai Shen",
    },
    {
      name: "PT Kingdoms Rise Sands of Fury",
    },
    {
      name: "PT Kingdoms Rise Forbidden Forest",
    },
  ];

  const como_juega = {
    id: "como_juega",
    p: [
      "¿Recuerdas la escena de Ocean’s Eleven con Brad Pitt, George Cloney, Andi García y Julia Roberts donde en un casino de las Vegas ingresan una moneda a una máquina tragamonedas y gana mucho dinero esa persona?",
      "Bueno eso es un JACKPOT y esa persona puedes ser tú jugando en un slot o tragamonedas del Casino Codere online para ganar un premio millonario.",
      "Claro, ahora todo ha evolucionado para que pases más y mejores horas de diversión, esto te lo decimos porque ya no deberás esperar a tirar y esperar a que se alineen el mismo símbolo. Ahora, encontrarás múltiples rodillos que pueden girar en diferentes direcciones para crear muchas combinaciones que te pueden hacer ganar.",
      "Podrás elegir entre los diferentes slots que tenemos disponibles en el casino Codere, elige entre las míticas historias de dioses, reyes, civilizaciones antiguas, tacos o hermosos animales animados que tienen millones esperando por ti.",
    ],
  };

  const reglas = {
    id: "anchor-2",
    h2: "Tu oportunidad con los jackpots es todos los días",
    p: {
      p1: "Te preguntarás, ¿cuál es la mejor hora para jugar y cazar un jackpot millonario? Bueno, la respuesta a cualquier hora puede ser la mejor. Eso es lo más divertido de los slots, ya que cuando menos te lo esperes tú puedes salir ganando. Es cierto, también puedes hacer un plan para divertirte y poder ser uno de los ganadores, aunque nunca está de más cruzar los dedos.",
      innerTitle:
        "4 Consejos para agarrar un premios millonario en los jackpot",
      innerTitleId: "anchor-3",
      list: [
        "Primero que todo, define un presupuesto para jugar, recuerda que la diversión también aguarda con un juego responsable.",
        "Echa un vistazo a los slots con jackpots disponibles, no siempre el que tenga una mayor premio acumulado será el más fácil de ganar.",
        "Alterna la cantidad de tus apuestas, arriesga un poco más en algunas ocasiones. Algunos aconsejan también jugar a los Jackpots de tipo progresivo ya que hay más posibilidades de que salgan en comparación con las slots que cuestan menos dinero.",
        "Disfruta el momento, por ahí dicen que todo llega cuando menos lo esperas. No olvides que siempre hay que jugar con cabeza y nunca abusar del tiempo y/o la cantidad de dinero con la que se puede jugar.",
      ],

      pi: [
        "En el Casino Codere hemos puesto a tu alcance diferentes tipos de jackpots, podrás decidir por los que explotan en determinado tiempo o cuando lleguen a determinada cantidad.",
        "¿Hasta no ver no creer? Te entendemos, jugar con dinero puede generar temor e incredibilidad, pero te queremos contar una historia muy real que ocurrió en Codere España, en enero de 2020, un usuario se puso a jugar de forma habitual su slot preferido de Gladiator Road to Rome sin saber que ese día tendría un golpe de suerte y ganaría una de las bolsas más grandes del Jackpot: ¡1,510,172.82 Euros! Y en nuestro país hemos entregado hasta 110 millones de pesos en un solo juego.",
        "¡Vuélvete el próximo ganador de Codere, ponte tu amuleto de la suerte, cruza los dedos y comienza a girar tu slot favorito, porque ganar en Codere es un relajo!",
      ],
    },
  };

  const floor1 = {
    id: "anchor-1",
    title: "¿Qué es un jackpot y cuales son sus diferentes tipos?",
    data: {
      p1: [
        "Un Jackpot es el premio máximo que un slot puede dar a un jugador. Este premio es independiente al que puedes ganar en tus giros y de la nada en alguno de los giros puede explotar el Jackpot y tú puedes ser el ganador." +
          "Mucho es cuestión de suerte pero también puedes generar una estrategia para lograrlo con mayor facilidad." +
          "Primero registrarte en Codere Colombia y obtén un increíble bono de bienvenida en el casino online Codere. Después da clic en la pestaña Crown del Casino y ve a la sección de Jackpots." +
          "Aquí verás todos los slots que tienen un jackpot disponible, en la etiqueta amarilla verás la cantidad que puedes ganar. En el casino Codere existen dos tipos de Jackpots:",
      ],
      p2: [
        "¡Relájate! Sabemos que hay una amplia variedad de juegos, por eso hemos creado una lista con la mejor selección de slots, juegos que su trama y temática te llevará a divertirte y ganar.",

        "En este momento encuentras con Jackpot los siguientes juegos:",
      ],

      list: [
        "5 Lucky Lions",
        "Wizards Want War",
        "Hot Hot Fruit",
        "Magic Qak",
        "5 Mariachis",
        "Panda Panda",
        "Ruffled Up",
        "12 Zodiacs",
        "Four Divine Beats",
        "Bird Of Thunder",
      ],
    },
  };

  return (
    <div className="cas-seo">
      {floating("¡Los mejores Jackpots!", true)}
      <div
        className="top-bg-seo "
        style={{
          backgroundImage: `url(${SPEEDSIZE_PREFIX}https://www.codere.com.co/colombia/images/seoCasinoImages/jackpots/${
            props.flag ? "JACKPOTS_mob" : "JACKPOTS"
          }.jpg)`,
          backgroundSize: "cover",
        }}
      ></div>

      <Container style={{ color: "#fff" }}>
        <h1 className="header mt-4 mb-3">{top_par.h1}</h1>

        {/* Table */}
        {!props.flag ? (
          <TableOfContents table={table_of_contents_list} />
        ) : null}

        <div className="mt-4">
          {como_juega.p.map((p, k) => (
            <p key={k}>{p}</p>
          ))}
        </div>
        {/* Games */}
        <LiveGamesDisplay live_games={live_games} device={props} />

        <div id={floor1.id}>
          <h2 className="mt-4 mb-3">{floor1.title}</h2>
          {floor1.data.p1.map((p, k) => (
            <p key={k}>{p}</p>
          ))}

          {dynamicTable(tableData)}

          {floor1.data.p2.map((p, k) => (
            <p key={k}>{p}</p>
          ))}

          <ul>
            {floor1.data.list.map((p, k) => (
              <li key={k}>{p}</li>
            ))}
          </ul>
        </div>

        <div>
          <h2 id={reglas.id} className="mt-4 mb-3">
            {reglas.h2}
          </h2>
          <p>{reglas.p.p1} </p>
          <h2 id={reglas.p.innerTitleId} className="mt-4 mb-3">
            {reglas.p.innerTitle}
          </h2>
          <ul>
            {reglas.p.list.map((li, k) => (
              <li key={k}>{li}</li>
            ))}
          </ul>
          {reglas.p.pi.map((p, k) => (
            <p key={k}>{p}</p>
          ))}
          <Button
            href={"https://m.codere.com.co/deportesCol/#/mso/RegistroCONewPage"}
            className="cas-reg-btn"
            rel="nofollow"
          >
            Registrate
          </Button>
        </div>
      </Container>
    </div>
  );
}
