import React, { useState, useEffect } from "react";
import { Swiper, SwiperSlide } from "swiper/react";
import { Autoplay, Pagination, EffectFade } from "swiper";
import SlideButton from "./Slide-Button";
import { Button, Modal } from "react-bootstrap";

import initialBanners from "../../JSON/data.json";

import "swiper/css";
import "swiper/css/lazy";
import "swiper/css/pagination";
import "swiper/css/effect-fade";
import "swiper/css/autoplay";

import { observer } from "mobx-react";
import myStore from "../../mobX/Store";
import { Arrowrightw } from "../../icons";

function getWidth() {
  if (myStore.segmented) return myStore.flag ? "300" : "400";
  else return myStore.flag ? "320" : "400";
}

function getHeight() {
  if (myStore.segmented) return myStore.flag ? "410" : "102";
  else return myStore.flag ? "440" : "140";
}

function dateInBetween(banner) {
  return (
    new Date() >= new Date(banner.startDate) &&
    new Date() <= new Date(banner.endDate)
  );
}

function BannerFilter(banner) {
  if (!banner.scheduled) return banner;
  else if (dateInBetween(banner)) return banner;
}

const REGIS_TEXT = "Regístrate";

const MySwiper = observer(({ segmented_banners }) => {
  if (segmented_banners) {
    initialBanners = segmented_banners;
  }

  const [imgs, setImgs] = useState([]);
  const [swiper, setSwiper] = useState(null);
  const [currentTycType, setCurrentTycType] = useState("");
  const [currentBannerName, setCurrentBannerName] = useState("");
  const [showTycModal, setShowTycModal] = useState(false);
  const [ctaText, setCtaText] = useState(REGIS_TEXT);
  const [ctaLink, setCtaLink] = useState(myStore.regis);

  // const [regis, setRegis] = useState(myStore.regis);

  useEffect(() => {
    if (myStore.flag !== null) {
      let device = myStore.flag === true ? "mobile" : "desktop";
      let key = `${device}_slide_list`;
      let tempList = [];

      if (segmented_banners)
        tempList = segmented_banners[key].filter(BannerFilter);
      else tempList = initialBanners[key].filter(BannerFilter);

      setImgs([...tempList]);
    }
  }, [myStore.flag, segmented_banners]);

  useEffect(() => {
    if (swiper) {
      if (showTycModal) swiper.autoplay.pause();
    }
  }, [showTycModal]);

  const indexHandler = (swiper) => {
    setSwiper(swiper);

    setCurrentTycType(imgs[swiper.realIndex].tycType || "");
    setCurrentBannerName(imgs[swiper.realIndex].name || "");

    setCtaText(
      imgs[swiper.realIndex].ctaText ||
        (myStore.segmented ? "Depositar" : "Regístrate")
    );

    setCtaLink(
      imgs[swiper.realIndex].ctaURL ||
        (myStore.segmented ? myStore.acceder : myStore.regis)
    );
  };

  const modalGenerator = () => {
    return (
      <>
        <Modal
          centered
          show={showTycModal}
          onHide={() => setShowTycModal(false)}
          contentClassName="terms-tyc"
        >
          <Modal.Header closeButton>
            <Modal.Title>
              <div className="test">
                <h5>Términos y condiciones</h5>
                <h5>{currentBannerName}</h5>
              </div>
            </Modal.Title>
          </Modal.Header>
          <Modal.Body>
            {currentTycType && tycGenerator(currentTycType)}
          </Modal.Body>
          <Modal.Footer>
            <Button
              onClick={() => setShowTycModal(false)}
              className="accept-btn"
            >
              Aceptar
            </Button>
          </Modal.Footer>
        </Modal>
      </>
    );
  };

  const tycGenerator = (type) => {
    let location = window.location.hostname;
    console.log(type);
    return (
      <>
        <iframe
          width="100%"
          height="400px"
          title="tyc"
          src={
            location.includes("codere.com")
              ? `https://www.codere.com.co/tyc/col-tyc-${type}.html` // for prd env
              : `https://blog.codere.com.co/terminos-y-condiciones/${type}` // for testing env
          }
        />
      </>
    );
  };

  return (
    <div id="carousel-section">
      {imgs.length && (
        <Swiper
          onSlideChange={(swiper) => indexHandler(swiper)}
          modules={[Pagination, EffectFade, Autoplay]}
          pagination={{ clickable: true }}
          effect="fade"
          // lazy={{ loadPrevNext: true, loadPrevNextAmount: 1 }}
          autoplay={{ delay: 3250, disableOnInteraction: true }}
          loop={true}
        >
          {imgs.map((item, index) => (
            <SwiperSlide key={index}>
              <a href={ctaLink}>
                <img
                  width={getWidth()}
                  height={getHeight()}
                  src={item.image}
                  alt={item.name}
                />
              </a>
            </SwiperSlide>
          ))}
        </Swiper>
      )}

      {!myStore.flag ? (
        <div id="des-reg">
          <Button
            className={`central-regis gl-effect ${
              myStore.segmented ? "segmentedReactSwipeButton" : ""
            }`}
            href={ctaLink}
            rel={"nofollow"}
          >
            {ctaText}
            <Arrowrightw />
          </Button>
          {currentTycType && (
            <span className="tyc" onClick={() => setShowTycModal(true)}>
              Términos y condiciones
            </span>
          )}
        </div>
      ) : (
        <>
          {currentTycType && (
            <div className="mob-tyc">
              <span className="tyc" onClick={() => setShowTycModal(true)}>
                Términos y condiciones
              </span>
            </div>
          )}
        </>
      )}

      {myStore.flag && (
        <SlideButton
          regis={ctaLink}
          ctaText={myStore.segmented ? "Acceder" : ctaText}
          segmented={myStore.segmented}
        />
      )}

      {modalGenerator()}
    </div>
  );
});

export default MySwiper;
