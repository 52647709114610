import { Link } from "react-router-dom";
import { isMobile } from "react-device-detect";
import { LazyLoadImage } from "react-lazy-load-image-component";
import { SPEEDSIZE_PREFIX } from "../../App";
export default function Navigations(props) {
  let type = props.type;
  let index = props.index;
  const prefix =
    type === 1
      ? `${SPEEDSIZE_PREFIX}https://www.codere.com.co/Colombia/images/casinoIcons/`
      : `${SPEEDSIZE_PREFIX}https://www.codere.com.co/Colombia/images/seo-sport/icons/`

  const eNavStyleDesktop = {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    textAlign: "center",
    gap: "2rem",
  };
  const eNavStyleMobile = {
    display: "flex",
    alignItems: "center",
    justifyContent: `${type ? "unset" : "center"}`,
    textAlign: "center",
    paddingLeft: "1.25rem",
    paddingRight: "1.25rem",
    gap: "1rem",
  };

  const navs = [
    [
      // Type 0: Eventos
      {
        icon: "mundial",
        title: "Mundial",
        url: "/eventos-deportivos/apuestas-mundial-fifa",
      },
      {
        icon: "baloncesto",
        title: "Baloncesto",
        url: "/eventos-deportivos/apuestas-mundial-baloncesto",
      },
      {
        icon: "mlb",
        title: "MLB",
        url: "/eventos-deportivos/apuestas-juego-de-las-estrellas",
      },
      {
        icon: "superbowl",
        title: "Superbowl",
        url: "/eventos-deportivos/apuestas-superbowl",
      },
    ],
    [
      // Type 1: Casino
      { icon: "casino", title: "Casino", url: "/casino" },
      { icon: "en-vivo", title: "En Vivo", url: "/casino/casino-en-vivo" },
      { icon: "slots", title: "Slots", url: "/casino/slots" },
      { icon: "blackjack", title: "Blackjack", url: "/casino/blackjack" },
      { icon: "ruleta", title: "Ruleta", url: "/casino/ruleta" },
      { icon: "jackpots", title: "Jackpots", url: "/casino/jackpots" },
      { icon: "baccarat", title: "Baccarat", url: "/casino/baccarat" },
      { icon: "bingo", title: "Bingo", url: "/casino/video-bingo" },
    ],
    [
      // Type 2: Cuotas
      {
        icon: "baloncesto",
        title: "Baloncesto",
        url: "/cuotas-deportivas/apuestas-baloncesto",
      },
      {
        icon: "formula1",
        title: "Formula 1",
        url: "/cuotas-deportivas/apuestas-formula1",
      },
      {
        icon: "soccer",
        title: "Fútbol ",
        url: "/cuotas-deportivas/apuestas-futbol",
      },
      {
        icon: "cycling",
        title: "Ciclismo",
        url: "/cuotas-deportivas/apuestas-ciclismo",
      },
      {
        icon: "superbowl",
        title: "NFL",
        url: "/cuotas-deportivas/apuestas-nfl",
      },
      { icon: "boxeo", title: "UFC", url: "/cuotas-deportivas/apuestas-ufc" },
      {
        icon: "tenis",
        title: "Tenis",
        url: "/cuotas-deportivas/apuestas-tenis",
      },
    ],
  ];

  // const validType = type >= 0 && type < navs.length;
  // console.log(validType)
  return (
    <div
      className="e-nav"
      style={!isMobile ? eNavStyleDesktop : eNavStyleMobile}
    >
      {navs[props.type].map((n, k) => (
        <Link
          to={n.url}
          style={{ color: "#fff", textDecoration: "none" }}
          key={k}
        >
          <LazyLoadImage
            src={prefix + n.icon + ".svg"}
            className={index === k + 1 ? "colored" : ""}
            style={isMobile ? { height: 32 } : { height: "2rem" }}
          />
          <span className={index === k + 1 ? "colored" : ""}>{n.title}</span>
        </Link>
      ))}
    </div>
  );
}
